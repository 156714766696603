import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_rdrequests from './create_newaccounts';
import { useLocation } from 'react-router-dom';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Employee_view_recurring_deposit(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  

    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //SETTING DATA VARIABLES
 const[application_no,setapplication_no]=useState();
 const[tr_no,settr_no]=useState();
 const[dtr_no,setdtr_no]=useState();
 const[rd_number,setrd_number]=useState();
 const[branch_code,setbranch_code]=useState();
 const[group_name,setgroup_name]=useState();
 const[account_number,setaccount_number]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[adr_number,setadr_number]=useState();
 const[nominee_name,setnominee_name]=useState();
 const[nominee_fathers_name,setnominee_fathers_name]=useState();
 const[nominee_contact,setnominee_contact]=useState();
 const[nominee_relation,setnominee_relation]=useState();
 const[nominee_adr_number,setnominee_adr_number]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[scheme_type,setscheme_type]=useState();

 const[initial_deposit_amt,setinitial_deposit_amt]=useState();
 const[additional_deposit_amt,setadditional_deposit_amt]=useState();
 const[total_deposit_amt,settotal_deposit_amt]=useState();
 const[actual_deposit_tilldate,setactual_deposit_tilldate]=useState();
 const[last_deposit_date,setlast_deposit_date]=useState();
 const[installments,setinstallments]=useState();
 const[installment_amount,setinstallment_amount]=useState();
 const[installment_frequency_indays,setinstallment_frequency_indays]=useState();
 const[initial_deposit_date,setinitial_deposit_date]=useState();
 const[installment_start_date,setinstallment_start_date]=useState();
 const[installment_end_date,setinstallment_end_date]=useState();
 const[paid_installments,setpaid_installments]=useState();
 const[due_installments,setdue_installments]=useState();
 const[installments_shortfall,setinstallments_shortfall]=useState();
 const[last_amt_update_date,setlast_amt_update_date]=useState();
 const[last_principle,setlast_principle]=useState();
 const[last_interest,setlast_interest]=useState();
 const[interest_amount,setinterest_amount]=useState();
 const[maturity_total_interest_amount,setmaturity_total_interest_amount]=useState();
 const[maturity_total_deposits,setmaturity_total_deposits]=useState();
 const[maturity_total_amount,setmaturity_total_amount]=useState();
 const[duration_indays,setduration_indays]=useState();
 const[maturity_yearly_interest_rate,setmaturity_yearly_interest_rate]=useState();
 const[maturity_monthly_interest_rate,setmaturity_monthly_interest_rate]=useState();
 const[maturity_daily_interest_rate,setmaturity_daily_interest_rate]=useState();
 const[prematurity_yearly_interest_rate,setprematurity_yearly_interest_rate]=useState();
 const[prematurity_monthly_interest_rate,setprematurity_monthly_interest_rate]=useState();
 const[prematurity_daily_interest_rate,setprematurity_daily_interest_rate]=useState();
 const[application_status,setapplication_status]=useState();
 const[approval_date,setapproval_date]=useState();
 const[approval_time,setapproval_time]=useState();
 const[approved_by,setapproved_by]=useState();
 const[rd_status,setrd_status]=useState();
 const[deposit_amount,setdeposit_amount]=useState();
 const[maturity_amount,setmaturity_amount]=useState();
 const[deposit_date,setdeposit_date]=useState();
 const[maturity_date,setmaturity_date]=useState();
 const[deposit_mode,setdeposit_mode]=useState();
 const[deposit_bank_id,setdeposit_bank_id]=useState();
 const[deposit_bank_name,setdeposit_bank_name]=useState();

 const[application_remarks,setapplication_remarks]=useState();
 const[document_attachment,setdocument_attachment]=useState();
 const[date_created,setdate_created]=useState();
 const[time_created,settime_created]=useState();
 const[created_by,setcreated_by]=useState();
 const[date_of_birth,setdate_of_birth]=useState();
 const[gender,setgender]=useState();
 const[address,setaddress]=useState();
 const[city,setcity]=useState();
 const[cstate,setcstate]=useState();
 const[pincode,setpincode]=useState();
 const[customer_image_path,setcustomer_image_path]=useState();

 const[interest_calculated_fordays,setinterest_calculated_fordays]=useState();

//Header
const[haddress,sethaddress]=useState(sessionStorage.getItem("headerAddress00"));const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState(sessionStorage.getItem("headerCity00"));const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState(sessionStorage.getItem("headerState00"));const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState(sessionStorage.getItem("headerPincode00"));const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState(sessionStorage.getItem("headerEmail00"));const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState(sessionStorage.getItem("headerContact00"));const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  
  // gdrive data
  const[gdrive_data,setgdrive_data]=useState([],);


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW RECURRING DEPOSIT FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)&&(branch_id>0)){
 
 axios.post("crm-employee-view-recurring-deposit", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setapplication_no(response.data.application_no);
   setdtr_no(response.data.dtr_no);
   setrd_number(response.data.rd_number);
   setbranch_code(response.data.branch_code);
   setgroup_name(response.data.group_name);
   setaccount_number(response.data.account_number);
   setcustomer_id(response.data.customer_id);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setemail(response.data.email);
   setadr_number(response.data.adr_number);
   setnominee_name(response.data.nominee_name);
   setnominee_fathers_name(response.data.nominee_fathers_name);
   setnominee_relation(response.data.nominee_relation);
   setnominee_contact(response.data.nominee_contact);
   setnominee_adr_number(response.data.nominee_adr_number);
   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setscheme_type(response.data.scheme_type);

   setinitial_deposit_amt(response.data.initial_deposit_amt);
   setadditional_deposit_amt(response.data.additional_deposit_amt);
   settotal_deposit_amt(response.data.total_deposit_amt);
   setactual_deposit_tilldate(response.data.actual_deposit_tilldate);
   setlast_deposit_date(response.data.last_deposit_date);
   setinstallments(response.data.installments);
   setinstallment_amount(response.data.installment_asetinstallment_amount);
   setinstallment_frequency_indays(response.data.installmentsetinstallment_frequency_indays);
   setinitial_deposit_amt(response.data.initial_deposit_amt);
   setadditional_deposit_amt(response.data.additional_deposit_amt);
   settotal_deposit_amt(response.data.total_deposit_amt);
   setactual_deposit_tilldate(response.data.actual_deposit_tilldate);
   setlast_deposit_date(response.data.last_deposit_date);
   setinstallments(response.data.installments);
   setinstallment_amount(response.data.installment_amount);
   setinstallment_frequency_indays(response.data.installment_frequency_indays);
   setinitial_deposit_date(response.data.initial_deposit_date);
   setinstallment_start_date(response.data.installment_start_date);
   setinstallment_end_date(response.data.installment_end_date);
   setpaid_installments(response.data.paid_installments);
   setdue_installments(response.data.due_installments);
   setinstallments_shortfall(response.data.installments_shortfall);
   setlast_amt_update_date(response.data.last_amt_update_date);
   setlast_principle(response.data.last_principle);
   setlast_interest(response.data.last_interest);
   setinterest_amount(response.data.interest_amount);
   setmaturity_total_interest_amount(response.data.maturity_total_interest_amount);
   setmaturity_total_deposits(response.data.maturity_total_deposits);
   setmaturity_total_amount(response.data.maturity_total_amount);
   setduration_indays(response.data.duration_indays);
   setmaturity_yearly_interest_rate(response.data.maturity_yearly_interest_rate);
   setmaturity_monthly_interest_rate(response.data.maturity_monthly_interest_rate);
   setmaturity_daily_interest_rate(response.data.maturity_daily_interest_rate);
   setprematurity_yearly_interest_rate(response.data.prematurity_yearly_interest_rate);
   setprematurity_monthly_interest_rate(response.data.prematurity_monthly_interest_rate);
   setprematurity_daily_interest_rate(response.data.prematurity_daily_interest_rate);
   setapplication_status(response.data.application_status);
   setapproval_date(response.data.approval_date);
   setapproval_time(response.data.approval_time);
   setapproved_by(response.data.approved_by);
   setrd_status(response.data.rd_status);
   setdeposit_amount(response.data.deposit_amount);
   setdeposit_date(response.data.deposit_date);
   setdeposit_mode(response.data.deposit_mode);
   setdeposit_bank_id(response.data.deposit_bank_id);
   setdeposit_bank_name(response.data.deposit_bank_name);
   setdocument_attachment((response.data.document_attachment).slice(7));
   setdate_created(response.data.date_created);
   settime_created(response.data.time_created);
   setcreated_by(response.data.created_by);
   setdate_of_birth(response.data.date_of_birth);
   setgender(response.data.gender);
   setaddress(response.data.address);
   setcity(response.data.city);
   setcstate(response.data.state);
   setpincode(response.data.pincode);
   setcustomer_image_path((response.data.customer_image_path).slice(7));
   setmaturity_date(response.data.maturity_date);

   setinterest_calculated_fordays(response.data.interest_calculated_fordays);


  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* VIEW RECURRING DEPOSIT FUNCTION END  ********************//


 
 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-recurring-deposits")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">RECURRING DEPOSIT (RD)</p>
                  </a>
                </div>
        
              </div>
            </div>


          {/*}  <button class="btn btn-warning" onClick={()=>{
            document.getElementById("upd-header-card").style.display="block";
            }}>update header</button> */}
         {/*}    <button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
             </button> */}
             


           {/* UPDATE HEADER INFO SECTION START 
            <div class="card" id="upd-header-card" style={{display:"none"}}>
              
              <div class="card-body" style={{textAlign:"left"}}>
                <h4 class="card-title">Update Header Information</h4>
                <div class="form-sample">
                  <p class="card-description">update header information</p>
                  
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Address</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="haddress" value={haddress} onChange={updatehaddress} />
                          <code>{haddress_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header City</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcity" value={hcity} onChange={updatehcity} />
                          <code>{hcity_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header State</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hstate" value={hstate} onChange={updatehstate} />
                          <code>{hstate_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Pincode</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hpincode" value={hpincode} onChange={updatehpincode} />
                          <code>{hpincode_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Email</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hemail" value={hemail} onChange={updatehemail} />
                          <code>{hemail_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Contact</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcontact" value={hcontact} onChange={updatehcontact} />
                          <code>{hcontact_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>

                  
                  
                   {/*} <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{
                       sethaddress(document.getElementById("haddress").value);
                       sethcity(document.getElementById("hcity").value);
                       sethstate(document.getElementById("hstate").value);
                       sethpincode(document.getElementById("hpincode").value);
                       sethemail(document.getElementById("hemail").value);
                       sethcontact(document.getElementById("hcontact").value);
                    }}>Update</button> */}

                 {/*}   <button class="btn btn-lg btn-outline-danger" style={{fontWeight:"bold"}} onClick={()=>{ 
                      document.getElementById("upd-header-card").style.display="none";
                    }}>
                      Close
                    </button>
                   

                 
               
                </div>
              </div>
            </div>
            {/* UPDATE HEADER INFO SECTION END */}

                <div class="card" style={{marginTop:"2rem"}}>
              
                  <div class="card-body" style={{textAlign:"left"}}>

                  {/* Print Section Start */}
                  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>
                  {/* SANCTION LETTER HEADER START */}


            {/*}      <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div> */}
                    
                    {/* SANCTION LETTER HEADER END */}
                    
                      <h2 style={{textAlign:"center", marginTop:"2rem"}}>Recurring Deposit (RD)</h2>
                      <hr/>
                      <div class="row d-print-flex mt-3">
                      <div class="row col-md-4 col d-print-inline-block"><b>Customer Image:</b></div>
                      <div class="row col-md-4 col d-print-inline-block"></div>
                      <div class="row col-md-4 col d-print-inline-block"><img style={{height:"18rem", width:"80%"}} src={imgpath+customer_image_path} /></div>
                      </div>

                      <h3 class="card-title">CUSTOMER DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row d-print-flex" style={{textAlign:"left"}}>

                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Id:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{contact}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Group Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{group_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Date of Birth:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{date_of_birth}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Gender:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{gender}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{account_number}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{branch_code}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12">
                    <div class="row col-sm-3"> <p><b>Address:</b></p></div>
                    <div class="row col-sm-9" > <p>{address}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>City:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{city}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>State:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{cstate}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Pincode:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{pincode}</p></div>
                    </div>
                    </div>


                    <hr/>
                    <h3 class="card-title">NOMINEE DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Full Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Father's Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_fathers_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Relation:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_relation}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Nominee Adr. Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>XXXXXXXX{nominee_adr_number}</p></div>
                    </div>
                 {/*}   <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Cibil Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_cibil_date}</p></div>
                    </div> */}
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_contact}</p></div>
                    </div>
                    </div>
                 
                   
                

                      
                      <hr/>


                      <h3 class="card-title">RECURRING DEPOSIT (RD) DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Application No.:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{application_no}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_code}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_name}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{deposit_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{deposit_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Rate (yearly):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_yearly_interest_rate} %</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Installments:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{installments}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Installment Start Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{installment_start_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_date}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Last Principle Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{last_principle}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Calculated for Days:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_calculated_fordays}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Till Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_amount}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Principle:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_total_deposits}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Interest:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_total_interest_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_total_amount}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Installment Frequency in Days:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{installment_frequency_indays}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Installment End Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{installment_end_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration in Days:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays} days</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Initial Deposit Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{initial_deposit_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Last Installment Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{last_amt_update_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration in Days:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays} days</p></div>
                    </div>
                    </div>
              {/*}      <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Interest Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_amount}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration (in months):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_inmonths}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration (in days):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_date}</p></div>
                    </div>
                    </div> */}
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Mode:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{deposit_mode}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>RD Status:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>   <label className="badge badge-danger" hidden={(rd_status=="in progress")||(rd_status=="withdrawn")||(rd_status=="completed")}>approved</label>
                    <label className="badge badge-success" hidden={(rd_status=="approved")||(rd_status=="withdrawn"||(rd_status=="completed"))}>in progress</label>
                    <label className="badge badge-warning" hidden={(rd_status=="in progress")||(rd_status=="approved")||(rd_status=="completed")}>withdrawn</label>                       
                    <label className="badge badge-primary" hidden={(rd_status=="in progress")||(rd_status=="approved"||(rd_status=="withdrawn"))}>completed</label>                       
                   </p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Approval Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{approval_date}</p></div>
                    </div>
                    </div>
                  

               
                    <br/>
                    <br/>
                {/*}    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                    <div class="row col-md-12 d-print-flex">
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Customer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Loan Officer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Branch Seal & Signature</b></p></div>
                    </div> */}
                    </div>
                    
                    </div>
                    </div>

                    {/* Print Section End */}

                

                    <hr />
                    <h3 class="card-title">DOCUMENTS AND ATTACHMENTS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Application Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{application_remarks}</p></div>
                    </div>
                  
                    <br/>
                    <a href={imgpath+document_attachment}><button class="btn btn-dark" >View Document Attachment</button></a>


                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                   
                {/*}    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+self_certification_image_link} style={{height:"90vh", width:"100%"}} /></div>
                    </row>*/}
                   
                    </div>
                  </div>
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_view_recurring_deposit;