import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_rdrequests from './create_newaccounts';
import moment from 'moment/moment';


function Employee_rd_application(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const custId=sessionStorage.getItem("CustomerId00");


 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    
    },[])


    

//******************* GET BANKS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)){
 
 axios.post("crm-employee-get-banks-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
 //  'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
   'Branch_id' :branch_id,

   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET BANKS DATA FUNCTION END  ********************//

 const[customer_id,setcustomer_id]=useState();const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
 const[scheme_code,setscheme_code]=useState();const updatescheme_code = (event) => { setscheme_code(event.target.value);}; const[scheme_code_er,setscheme_code_er]=useState();
 const[deposit_date,setdeposit_date]=useState();const updatedeposit_date = (event) => { setdeposit_date(event.target.value);}; const[deposit_date_er,setdeposit_date_er]=useState();
 const[deposit_amount,setdeposit_amount]=useState();const updatedeposit_amount = (event) => { setdeposit_amount(event.target.value);}; const[deposit_amount_er,setdeposit_amount_er]=useState();
 const[mode,setmode]=useState();const updatemode = (event) => { setmode(event.target.value);}; const[mode_er,setmode_er]=useState();
 const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
 const[bank_name,setbank_name]=useState();const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();
 //const[customer_id,setcustomer_id]=useState();const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
 const[remarks,setremarks]=useState();const updateremarks = (event) => { setremarks(event.target.value);}; const[remarks_er,setremarks_er]=useState();


 const[document_file_name,setdocument_file_name]=useState();const updatedocument_file_name = (event) => { setdocument_file_name(event.target.value);}; const[document_file_name_er,setdocument_file_name_er]=useState();
 const[document_file_link,setdocument_file_link]=useState();const updatedocument_file_link = (event) => { setdocument_file_link(event.target.value);}; const[document_file_link_er,setdocument_file_link_er]=useState();

 const[nominee_name,setnominee_name]=useState();const updatenominee_name = (event) => { setnominee_name(event.target.value);}; const[nominee_name_er,setnominee_name_er]=useState();
 const[nominee_fathers_name,setnominee_fathers_name]=useState();const updatenominee_fathers_name = (event) => { setnominee_fathers_name(event.target.value);}; const[nominee_fathers_name_er,setnominee_fathers_name_er]=useState();
 const[nominee_relation,setnominee_relation]=useState();const updatenominee_relation = (event) => { setnominee_relation(event.target.value);}; const[nominee_relation_er,setnominee_relation_er]=useState();
 const[nominee_contact,setnominee_contact]=useState();const updatenominee_contact = (event) => { setnominee_contact(event.target.value);}; const[nominee_contact_er,setnominee_contact_er]=useState();
 const[nominee_adr_number,setnominee_adr_number]=useState();const updatenominee_adr_number = (event) => { setnominee_adr_number(event.target.value);}; const[nominee_adr_number_er,setnominee_adr_number_er]=useState();

 const[installment_amount,setinstallment_amount]=useState();const updateinstallment_amount = (event) => { setinstallment_amount(event.target.value);}; const[installment_amount_er,setinstallment_amount_er]=useState();
 const[installment_start_date,setinstallment_start_date]=useState();const updateinstallment_start_date = (event) => { setinstallment_start_date(event.target.value);}; const[installment_start_date_er,setinstallment_start_date_er]=useState();


 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[employee_image_link_er,setemployee_image_link_er]=useState();
 
 //image upload
 //File Upload
 const [selectedFile1, setSelectedFile1] = React.useState(null);
 const handleFileChange1 = (event) => {  const file1 = event.target.files[0];  setSelectedFile1(file1); };
 const[employee_docs_link_er,setemployee_docs_link_er]=useState();
 

 

/***************** CREATE RD APPLICATION FUNCTION START  ******************/
function create_rd_application(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(branch_id>0)&&((custId!=null)||(custId!=""))){

    //Setting Error Variables
    setcustomer_id_er(" "); setscheme_code_er(" "); setdeposit_date_er(" "); setdeposit_amount_er(" "); setmode_er(" "); setbank_id_er(" ");
    setnominee_name_er(" "); setnominee_contact_er(" "); setnominee_fathers_name_er(" "); setnominee_relation_er(" "); setnominee_adr_number_er(" ");
    setdocument_file_name_er(" ");setdocument_file_link_er(" "); setinstallment_amount_er(" "); setinstallment_start_date_er(" ");
    seterror_msg(" ");

   
    
//Fetching Form Data
let noerror = 0;
const $customer_id = document.getElementById("customer_id").value; if($customer_id==""){ setcustomer_id_er("Customer Id is required !");noerror = noerror+1; }//customer_id
const $scheme_code = document.getElementById("scheme_code").value; if($scheme_code==""){ setscheme_code_er("Scheme Code is required !");noerror = noerror+1; }//scheme_code
const $deposit_amount = document.getElementById("deposit_amount").value; if($deposit_amount==""){ setdeposit_amount_er("Deposit Amount is required !");noerror = noerror+1; }//deposit_amount
const $deposit_date = document.getElementById("deposit_date").value; if($deposit_date==""){ setdeposit_date_er("Deposit Date is required !");noerror = noerror+1; }//deposit_date
const $remarks = document.getElementById("remarks").value; if($remarks==""){ setremarks_er("Remarks is required !");noerror = noerror+1; }//remarks
const $mode = document.getElementById("mode").value; if($mode==""){ setmode_er("Mode is required !");noerror = noerror+1; }//mode

const $nominee_name = document.getElementById("nominee_name").value; if($nominee_name==""){ setnominee_name_er("Nominee Name is required !");noerror = noerror+1; }//nominee_name
const $nominee_contact = document.getElementById("nominee_contact").value; if($nominee_contact==""){ setnominee_contact_er("Nominee contact is required !");noerror = noerror+1; }//nominee_contact
const $nominee_fathers_name = document.getElementById("nominee_fathers_name").value; if($nominee_fathers_name==""){ setnominee_fathers_name_er("Nominee fathers_name is required !");noerror = noerror+1; }//nominee_fathers_name
const $nominee_relation = document.getElementById("nominee_relation").value; if($nominee_relation==""){ setnominee_relation_er("Nominee relation is required !");noerror = noerror+1; }//nominee_relation
const $nominee_adr_number = document.getElementById("nominee_adr_number").value; if($nominee_adr_number==""){ setnominee_adr_number_er("Nominee Adr Number is required !");noerror = noerror+1; }//nominee_adr_number

const $installment_amount = document.getElementById("installment_amount").value; if($installment_amount==""){ setinstallment_amount_er("Installment Amount is required !");noerror = noerror+1; }//installment_amount
const $installment_start_date = document.getElementById("installment_start_date").value; if($installment_start_date==""){ setinstallment_start_date_er("Installment Start Date is required !");noerror = noerror+1; }//installment_start_date


if($deposit_date==$installment_start_date){ seterror_msg("Deposit Date and Installment Start Date Cannot be same!"); noerror = noerror+1; }



//const $document_file_name = document.getElementById("document_file_name").value; if($document_file_name==""){ setdocument_file_name_er("Document File Name is required !");noerror = noerror+1; }//document_file_name
//const $document_file_link = document.getElementById("document_file_link").value; if($document_file_link==""){ setdocument_file_link_er("Document File Link is required !");noerror = noerror+1; }//document_file_link


//No primary errors
if(noerror==0){

    //disabling button
  //  document.getElementById('emp-submit-btn').disabled = true; 

  //Sending Data through axios
  axios.post("crm-employee-create-new-rd-request", { 
  Log_id:logid,
  Token:token,
  Branch_id:branch_id,
  Customer_id:$customer_id,
  Scheme_code:$scheme_code,
  Mode: $mode,
  Bank_id:bank_id,
  Deposit_date:moment($deposit_date).format('YYYY-MM-DD'),
  Deposit_amount:$deposit_amount,
  Remarks:$remarks,
  Nominee_name:$nominee_name,
  Nominee_contact:$nominee_contact,
  Nominee_fathers_name:$nominee_fathers_name,
  Nominee_relation:$nominee_relation,
  Nominee_adr_number:$nominee_adr_number,
  Installment_amount:$installment_amount,
  Installment_start_date:$installment_start_date,
  //Document_file_name:$document_file_name,
  //Document_file_link:$document_file_link,

//  Cibil_image_link:selectedFile,
  Document_attachment:selectedFile1,
//  Coapplicant_name:document.getElementById("coapplicant_name").value,
//  Coapplicant_fathers_name:document.getElementById("coapplicant_fathers_name").value,
//  Coapplicant_cibil_score:document.getElementById("coapplicant_cibil_score").value,
//  Coapplicant_cibil_date:moment(document.getElementById("coapplicant_cibil_date").value).format('YYYY-MM-DD'),
//  Coapplicant_contact:document.getElementById("coapplicant_contact").value,
//  Coapplicant_relation:document.getElementById("coapplicant_relation").value,
//  Coapplicant_address:document.getElementById("coapplicant_address").value,
//  Type:"regular"

  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

      //enabling button
    //  document.getElementById('emp-submit-btn').disabled = false; 

    
  //Validation Errors
  if(response.data.validatorerror){
  setcustomer_id_er(response.data.validatorerror.Customer_id);
  setscheme_code_er(response.data.validatorerror.Scheme_code);
  setmode_er(response.data.validatorerror.Mode);
  setbank_id_er(response.data.validatorerror.Bank_id);
  setdeposit_date_er(response.data.validatorerror.Deposit_date);
  setdeposit_amount_er(response.data.validatorerror.Deposit_amount);
  setremarks_er(response.data.validatorerror.Remarks);

  setnominee_name_er(response.data.validatorerror.Nominee_name);
  setnominee_fathers_name_er(response.data.validatorerror.Nominee_fathers_name);
  setnominee_contact_er(response.data.validatorerror.Nominee_contact);
  setnominee_relation_er(response.data.validatorerror.Nominee_relation);
  setnominee_adr_number_er(response.data.validatorerror.Nominee_adr_number); 

  setinstallment_amount_er(response.data.validatorerror.Installment_amount); 
  setinstallment_start_date_er(response.data.validatorerror.Installment_start_date); 

  seterror_msg("Recurring Deposit (RD) Application Not Created !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setcustomer_id_er(response.data.customer_id_er);
    setscheme_code_er(response.data.scheme_code_er);
    setmode_er(response.data.mode_er);
    setbank_id_er(response.data.bank_id_er);
    setdeposit_date_er(response.data.deposit_date_er);
    setdeposit_amount_er(response.data.deposit_amount_er);
    setremarks_er(response.data.remarks_er);
    setnominee_name_er(response.data.nominee_name_er);
    setnominee_fathers_name_er(response.data.nominee_fathers_name_er);
    setnominee_contact_er(response.data.nominee_contact_er);
    setnominee_relation_er(response.data.nominee_relation_er);
    setnominee_adr_number_er(response.data.nominee_adr_number_er); 
    setinstallment_amount_er(response.data.installment_amount_er); 
    setinstallment_start_date_er(response.data.installment_start_date_er); 

    seterror_msg("Recurring Deposit (RD) Application Not Created !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Recurring Deposit (RD) Application Not Created !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }

}
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE RD APPLICATION FUNCTION END  ******************/


 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Recurring Deposit (RD) Request Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-rd-requests"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-rd-requests")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">RECURRING DEPOSIT (RD) REQUEST</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Recurring Deposit (RD) Request</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="C656666" id="customer_id" value={customer_id} onChange={updatecustomer_id} />
                              <code>{customer_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" id="scheme_code" value={scheme_code} onChange={updatescheme_code} />
                              <code>{scheme_code_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="700" id="deposit_date" value={deposit_date} onChange={updatedeposit_date} />
                              <code>{deposit_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="deposit_amount" placeholder="15000.00" value={deposit_amount} onChange={updatedeposit_amount} />
                              <code>{deposit_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Mode</label>
                        <div class="col-sm-9">
                          <select class="form-control" style={{padding:"1rem"}} id="mode" value={mode} onChange={updatemode}>
                          <option value=" "> -- select mode -- </option>
                          <option value="cash">cash</option>
                          <option value="bank">bank</option>
                          </select>
                          <code>{mode_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Bank</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="bank_id" value={bank_id} onChange={updatebank_id}>
                            <option value=""> -- select bank -- </option>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                            <option value={curElm.Bank_id}> {curElm.Bank_name} </option>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                            </select>
                            <code>{bank_id_er}</code>
                            </div>
                          </div>
                        </div> 

                  </div>
                  <div class="row">
                  <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000" id="installment_amount" value={installment_amount} onChange={updateinstallment_amount} />
                              <code>{installment_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Start Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="1000" id="installment_start_date" value={installment_start_date} onChange={updateinstallment_start_date} />
                              <code>{installment_start_date_er}</code>
                            </div>
                          </div>
                        </div>
                  </div>
                
                        <div class="form-group">
                        <label for="exampleTextarea1">Application Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="remarks here" id="remarks" value={remarks} onChange={updateremarks} />
                        <code>{remarks_er}</code>
                        </div>


                        <hr/>
                        <h4 class="card-title">Nominee Details</h4>
                 
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Full Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Santosh Kumar" id="nominee_name" onChange={updatenominee_name} value={nominee_name} />
                              <code>{nominee_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Father's Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Hari Kumar" id="nominee_fathers_name" value={nominee_fathers_name} onChange={updatenominee_fathers_name} />
                              <code>{nominee_fathers_name_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Relation to Applicant</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="700" id="nominee_relation" value={nominee_relation} onChange={updatenominee_relation} />
                              <code>{nominee_relation_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91980000000" id="nominee_contact" value={nominee_contact} onChange={updatenominee_contact} />
                              <code>{nominee_contact_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Adr Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="700000" id="nominee_adr_number" value={nominee_adr_number} onChange={updatenominee_adr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>enter last 6 digits of govt. ID</small>
                              <code>{nominee_adr_number_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                      
                 
                        <hr/>
                        <h4>Attach Document</h4>
                     {/*}   <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents File Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Customer 1234" id="document_file_name" value={document_file_name} onChange={updatedocument_file_name}  />
                              <code>{document_file_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Documents Link</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="https://mydrivelink.com/mydrive" id="document_file_link" value={document_file_link} onChange={updatedocument_file_link} />
                              <code>{document_file_link_er}</code>
                            </div>
                          </div>
                        </div>                     
                      </div> */}

                    {/*}  <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Cibil Proof (Image)</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{employee_image_link_er}</code>
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link1" onChange={handleFileChange1} multiple />
                              {selectedFile1 && <p>Selected file: {selectedFile1.name}</p>}
                              <code>{employee_docs_link_er}</code>
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.pdf), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>


                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} id="emp-submit-btn" onClick={create_rd_application}>Submit</button>
                     
                     
                     
                     </div>
                     

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                       
                       
    
                     
                   
                   {/*} </div> */}
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_rd_application;