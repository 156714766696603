import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';



function Employee_fd_withdrawal(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const editId=sessionStorage.getItem("EditId00");


 
//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable


useEffect(()=>{
   
    getData();
    
    },[])


    
 
  //SETTING DATA VARIABLES
  const[fd_number,setfd_number]=useState();//const updatefd_number = (event) => { setfd_number(event.target.value);}; const[fd_number_er,setfd_number_er]=useState();
  const[branch_code,setbranch_code]=useState();//const updatebranch_code = (event) => { setbranch_code(event.target.value);}; const[branch_code_er,setbranch_code_er]=useState();
  const[customer_id,setcustomer_id]=useState();//const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
  const[account_number,setaccount_number]=useState();//const updateaccount_number = (event) => { setaccount_number(event.target.value);}; const[account_number_er,setaccount_number_er]=useState();
  const[first_name,setfirst_name]=useState();//const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
  const[last_name,setlast_name]=useState();//const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
  const[contact,setcontact]=useState();//const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
  const[adr_number,setadr_number]=useState();//const updateadr_number = (event) => { setadr_number(event.target.value);}; const[adr_number_er,setadr_number_er]=useState();
  const[nominee_name,setnominee_name]=useState();//const updatenominee_name = (event) => { setnominee_name(event.target.value);}; const[nominee_name_er,setnominee_name_er]=useState();
  const[nominee_contact,setnominee_contact]=useState();//const updatenominee_contact = (event) => { setnominee_contact(event.target.value);}; const[nominee_contact_er,setnominee_contact_er]=useState();
  const[nominee_fathers_name,setnominee_fathers_name]=useState();//const updatenominee_fathers_name = (event) => { setnominee_fathers_name(event.target.value);}; const[nominee_fathers_name_er,setnominee_fathers_name_er]=useState();
  const[nominee_adr_number,setnominee_adr_number]=useState();//const updatenominee_adr_number = (event) => { setnominee_adr_number(event.target.value);}; const[nominee_adr_number_er,setnominee_adr_number_er]=useState();
  const[scheme_code,setscheme_code]=useState();//const updatescheme_code = (event) => { setscheme_code(event.target.value);}; const[scheme_code_er,setscheme_code_er]=useState();
  const[scheme_name,setscheme_name]=useState();//const updatescheme_name = (event) => { setscheme_name(event.target.value);}; const[scheme_name_er,setscheme_name_er]=useState();
  const[duration_indays,setduration_indays]=useState();//const updateduration_indays = (event) => { setduration_indays(event.target.value);}; const[duration_indays_er,setduration_indays_er]=useState();
  const[duration_inmonths,setduration_inmonths]=useState();//const updateduration_inmonths = (event) => { setduration_inmonths(event.target.value);}; const[duration_inmonths_er,setduration_inmonths_er]=useState();
  const[maturity_yearly_interest_rate,setmaturity_yearly_interest_rate]=useState();//const updatematurity_yearly_interest_rate = (event) => { setmaturity_yearly_interest_rate(event.target.value);}; const[maturity_yearly_interest_rate_er,setmaturity_yearly_interest_rate_er]=useState();
  const[applicable_yearly_interest_rate,setapplicable_yearly_interest_rate]=useState();//const updateapplicable_yearly_interest_rate = (event) => { setapplicable_yearly_interest_rate(event.target.value);}; const[applicable_yearly_interest_rate_er,setapplicable_yearly_interest_rate_er]=useState();
  const[withdrawal_type,setwithdrawal_type]=useState();//const updatewithdrawal_type = (event) => { setwithdrawal_type(event.target.value);}; const[withdrawal_type_er,setwithdrawal_type_er]=useState();
  const[deposit_date,setdeposit_date]=useState();//const updatedeposit_date = (event) => { setdeposit_date(event.target.value);}; const[deposit_date_er,setdeposit_date_er]=useState();
  const[maturity_date,setmaturity_date]=useState();//const updatematurity_date = (event) => { setmaturity_date(event.target.value);}; const[maturity_date_er,setmaturity_date_er]=useState();
  const[deposit_amount,setdeposit_amount]=useState();//const updatedeposit_amount = (event) => { setdeposit_amount(event.target.value);}; const[deposit_amount_er,setdeposit_amount_er]=useState();
  const[duration_indays_completed,setduration_indays_completed]=useState();//const updateduration_indays_completed = (event) => { setduration_indays_completed(event.target.value);}; const[duration_indays_completed_er,setduration_indays_completed_er]=useState();
  const[duration_indays_shortfall,setduration_indays_shortfall]=useState();//const updateduration_indays_shortfall = (event) => { setduration_indays_shortfall(event.target.value);}; const[duration_indays_shortfall_er,setduration_indays_shortfall_er]=useState();
  const[applicable_interest_amount,setapplicable_interest_amount]=useState();//const updateapplicable_interest_amount = (event) => { setapplicable_interest_amount(event.target.value);}; const[applicable_interest_amount_er,setapplicable_interest_amount_er]=useState();
  const[amount_till_date,setamount_till_date]=useState();//const updateamount_till_date = (event) => { setamount_till_date(event.target.value);}; const[amount_till_date_er,setamount_till_date_er]=useState();
  const[processing_fee_per,setprocessing_fee_per]=useState();//const updateprocessing_fee_per = (event) => { setprocessing_fee_per(event.target.value);}; const[processing_fee_per_er,setprocessing_fee_per_er]=useState();
  const[processing_fee_amt,setprocessing_fee_amt]=useState();//const updateprocessing_fee_amt = (event) => { setprocessing_fee_amt(event.target.value);}; const[processing_fee_amt_er,setprocessing_fee_amt_er]=useState();
  const[payable_amount,setpayable_amount]=useState();//const updatepayable_amount = (event) => { setpayable_amount(event.target.value);}; const[payable_amount_er,setpayable_amount_er]=useState();

  const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState();
  const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
  const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();
  
  //Withdrawee Details
  const[withdrawee_name,setwithdrawee_name]=useState();const updatewithdrawee_name = (event) => { setwithdrawee_name(event.target.value);}; const[withdrawee_name_er,setwithdrawee_name_er]=useState();
  const[withdrawee_contact,setwithdrawee_contact]=useState();const updatewithdrawee_contact = (event) => { setwithdrawee_contact(event.target.value);}; const[withdrawee_contact_er,setwithdrawee_contact_er]=useState();
  const[withdrawee_email,setwithdrawee_email]=useState();const updatewithdrawee_email = (event) => { setwithdrawee_email(event.target.value);}; const[withdrawee_email_er,setwithdrawee_email_er]=useState();
  const[withdrawee_adr_number,setwithdrawee_adr_number]=useState();const updatewithdrawee_adr_number = (event) => { setwithdrawee_adr_number(event.target.value);}; const[withdrawee_adr_number_er,setwithdrawee_adr_number_er]=useState();
  const[withdrawee_type,setwithdrawee_type]=useState();const updatewithdrawee_type = (event) => { setwithdrawee_type(event.target.value);}; const[withdrawee_type_er,setwithdrawee_type_er]=useState();

  
  //const[amount,setamount]=useState();const updateamount = (event) => { setamount(event.target.value);}; const[amount_er,setamount_er]=useState();
  const[bank_id,setbank_id]=useState();const updatebank_id = (event) => { setbank_id(event.target.value);}; const[bank_id_er,setbank_id_er]=useState();
  const[bank_name,setbank_name]=useState();//const updatebank_name = (event) => { setbank_name(event.target.value);}; const[bank_name_er,setbank_name_er]=useState();

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 //image upload
 //File Upload
 const [selectedFile, setSelectedFile] = React.useState(null);
 const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };
 const[attachment_link_er,setattachment_link_er]=useState();

 


    

//******************* GET FD DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-employee-fd-withdrawal-default-load", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id : branch_id


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){


   //Setting Data
   setfd_number(response.data.fd_number);
   setbranch_code(response.data.branch_code);
   setcustomer_id(response.data.customer_id);
   setaccount_number(response.data.account_number);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setadr_number(response.data.adr_number);
   setnominee_name(response.data.nominee_name);
   setnominee_fathers_name(response.data.nominee_fathers_name);
   setnominee_contact(response.data.nominee_contact);
   setnominee_adr_number(response.data.nominee_adr_number);

   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setduration_indays(response.data.duration_indays);
   setduration_inmonths(response.data.duration_inmonths);
   setmaturity_yearly_interest_rate(response.data.maturity_yearly_interest_rate);
   setapplicable_yearly_interest_rate(response.data.applicable_yearly_interest_rate);
   setwithdrawal_type(response.data.withdrawal_type);
   setdeposit_date(response.data.deposit_date);
   setmaturity_date(response.data.maturity_date);
   setdeposit_amount(response.data.deposit_amount);
   setduration_indays_completed(response.data.duration_indays_completed);
   setduration_indays_shortfall(response.data.duration_indays_shortfall);
   setapplicable_interest_amount(response.data.applicable_interest_amount);
   setamount_till_date(response.data.amount_till_date);
   setprocessing_fee_per(response.data.processing_fee_per);
   setprocessing_fee_amt(response.data.processing_fee_amt);
   setpayable_amount(response.data.payable_amount);


  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET FD DATA FUNCTION END  ********************//


   
 
/***************** FD WITHDRAWAL APPLICATION FUNCTION START  ******************/
function create_withdrawal(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(branch_id)&&(editId>0)){


    //Setting Error Variables
    setdate_er(" ");settype_er(" ");setparticular_er(" ");setbank_id_er(" "); seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $withdrawee_name = document.getElementById("withdrawee_name").value; if($withdrawee_name==""){ setwithdrawee_name_er("Withdrawee Name is required !");noerror = noerror+1; }//date
const $withdrawee_contact = document.getElementById("withdrawee_contact").value; if($withdrawee_contact==""){ setwithdrawee_contact_er("Withdrawee Contact is required !");noerror = noerror+1; }//date
const $withdrawee_email = document.getElementById("withdrawee_email").value; if($withdrawee_email==""){ setwithdrawee_email_er("Withdrawee Email is required !");noerror = noerror+1; }//date
const $withdrawee_adr_number = document.getElementById("withdrawee_adr_number").value; if($withdrawee_adr_number==""){ setwithdrawee_adr_number_er("Withdrawee Adr_number is required !");noerror = noerror+1; }//date

const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $particular = document.getElementById("particular").value; if($particular==""){ setparticular_er("Particular is required !");noerror = noerror+1; }//particular
//const $amount = document.getElementById("amount").value; if($amount==""){ setamount_er("Amount is required !");noerror = noerror+1; }//amount
//const $bank_id = document.getElementById("bank_id").value; //if($bank_id==""){ setbank_id_er("Bank is required !");noerror = noerror+1; }//bank_id

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-employee-create-fd-withdrawal-application", { 
  Log_id:logid,
  Token:token,
 // Date:$date,
  Withdrawee_name:$withdrawee_name,
  Withdrawee_contact:$withdrawee_contact,
  Withdrawee_email:$withdrawee_email,
  Withdrawee_adr_number:$withdrawee_adr_number,
  Withdrawee_type:$type,
  Remarks:$particular,
 
  Document_attachment:selectedFile,
  Branch_id:branch_id,
  Edit_id:editId
 
  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {


  //Validation Errors
  if(response.data.validatorerror){
  setwithdrawee_name_er(response.data.validatorerror.Withdrawee_name);
  setwithdrawee_contact_er(response.data.validatorerror.Withdrawee_contact);
  setwithdrawee_email_er(response.data.validatorerror.Withdrawee_email);
  setwithdrawee_adr_number_er(response.data.validatorerror.Withdrawee_adr_number);
  settype_er(response.data.validatorerror.Withdrawee_type);
  setparticular_er(response.data.validatorerror.Particular);
  setattachment_link_er(response.data.validatorerror.File_attachment);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setwithdrawee_name_er(response.data.withdrawee_name_er);
  setwithdrawee_contact_er(response.data.withdrawee_contact_er);
  setwithdrawee_email_er(response.data.withdrawee_email_er);
  setwithdrawee_adr_number_er(response.data.withdrawee_adr_number_er);
  settype_er(response.data.withdrawee_type_er);
  setparticular_er(response.data.particular_er);
  //setamount_er(response.data.amount_er);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Comlete Transaction!"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE FD WITHDRAWAL FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Withdrawal Request Created Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/employee-dashboard-fd-withdrawals"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-fd-withdrawals")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">FIXED DEPOSIT (FD) WITHDRAWAL</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create FD Withdrawal Application</h4>
                    <div class="form-sample">
                      <p class="card-description">add withdrawee details</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">FD Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={fd_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Branch Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={branch_code} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        </div>
                        <hr/>
                        <h5>Customer Details</h5>
                        <div class="row">
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={customer_id} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Account Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={account_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>   
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">First Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={first_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Last Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={last_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={contact} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Adr. Number (last 6 digits)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={adr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>

                        <hr/>
                        <h5>Nominee Details</h5>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nominee Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={nominee_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nominee Father's Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={nominee_fathers_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nominee Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={nominee_contact} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Nominee Adr. Number (last 6 digits)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={nominee_adr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>

                        <hr/>
                        <h5>Fixed Deposit (FD) Details</h5>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={scheme_code} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={scheme_name} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Date</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={deposit_date} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maturity Date</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={maturity_date} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={deposit_amount} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Yearly Interest Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={maturity_yearly_interest_rate} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration (in months)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={duration_inmonths} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration (in days)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={duration_indays} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration Completed (in days)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={duration_indays_completed} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration Shortfall (in days)</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={duration_indays_shortfall} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Applicable Yearly Interest Rate</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={applicable_yearly_interest_rate} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={applicable_interest_amount} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Amount Till Date</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={amount_till_date} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Processing Fee %</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={processing_fee_per} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Processing Fee Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={processing_fee_amt} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"><b>Withdrawal Amount</b></label>
                            <div class="col-sm-9">
                              <input type="text" style={{fontWeight:"bold"}} class="form-control" value={payable_amount} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <hr/>
                        <h5>Withdrawee Details</h5>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawal Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" value={withdrawal_type} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawee Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Sanjeet Sharma" value={withdrawee_name} id="withdrawee_name" onChange={updatewithdrawee_name} />
                              <code>{withdrawee_name_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawee Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9900000000" value={withdrawee_contact} id="withdrawee_contact" onChange={updatewithdrawee_contact} />
                              <code>{withdrawee_contact_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawee Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="myemail@gmail.com" value={withdrawee_email} id="withdrawee_email" onChange={updatewithdrawee_email} />
                              <code>{withdrawee_email_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawee Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="self / nominee" value={type} id="type" onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Withdrawee Adr Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="000000" value={withdrawee_adr_number} id="withdrawee_adr_number" onChange={updatewithdrawee_adr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>enter last 6 digits of govt. Id</small>
                              <code>{withdrawee_adr_number_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>

  
                        <div class="form-group">
                        <label for="exampleTextarea1">Remarks</label>
                        <textarea  class="form-control" rows="2" placeholder="here particular" id="particular" value={particular} onChange={updateparticular} />
                        <code>{particular_er}</code>
                      </div>

                    
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attachment</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link"  onChange={handleFileChange} multiple />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{attachment_link_er}</code>
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={create_withdrawal}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_fd_withdrawal;