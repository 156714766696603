import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';



function Employee_finalanalysis(){


  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  
  
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //SETTING DATA VARIABLES
 const[loan_no,setloan_no]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[account_number,setaccount_number]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[loan_amount,setloan_amount]=useState();
 //const[interest_rate,setinterest_rate]=useState();
 const[repayable_amount,setrepayable_amount]=useState();
 //const[remaining_amount,setremaining_amount]=useState();
 //const[closure_per,setclosure_per]=useState();
 //const[closure_charge,setclosure_charge]=useState();
 const[lp_status,setlp_status]=useState();
 const[mode,setmode]=useState();
 const[bank_id,setbank_id]=useState();
 const[bank_name,setbank_name]=useState();
 //const[date,setdate]=useState();
 const[payment_date,setpayment_date]=useState();
 const[approval_date,setapproval_date]=useState();
 const[coapplicant_name,setcoapplicant_name]=useState();
 const[group_name,setgroup_name]=useState();
 const[duration,setduration]=useState();
 const[emi,setemi]=useState();
 const[paid_emis,setpaid_emis]=useState();
 const[due_emis,setdue_emis]=useState();
 const[emi_start_date,setemi_start_date]=useState();
 const[emi_end_date,setemi_end_date]=useState();
 const[last_emi_date,setlast_emi_date]=useState();
 const[partly_paid_emis,setpartly_paid_emis]=useState();
 const[amount,setamount]=useState();
 const[total,settotal]=useState();


 //OVERALL DATA
 const[customers_count,setcustomers_count]=useState();
 const[employees_count,setemployees_count]=useState();
 const[branches_count,setbranches_count]=useState();
 const[loan_schemes_count,setloan_schemes_count]=useState();
 const[loan_groups_count,setloan_groups_count]=useState();
 const[transactions_count,settransactions_count]=useState();

 //CASHFLOW DATA
 const[cash_inflow,setcash_inflow]=useState();
 const[cash_outflow,setcash_outflow]=useState();
 const[bank_inflow,setbank_inflow]=useState();
 const[bank_outflow,setbank_outflow]=useState();
 const[total_cash_inflow,settotal_cash_inflow]=useState();
 const[total_cash_outflow,settotal_cash_outflow]=useState();

 //LOANS
 const[total_loans_count,settotal_loans_count]=useState();
 const[approved_loans_count,setapproved_loans_count]=useState();
 const[completed_loans_count,setcompleted_loans_count]=useState();
 const[total_disbursements_count,settotal_disbursements_count]=useState();
 const[total_disbursements_amount,settotal_disbursements_amount]=useState();
 const[processing_fee_collection,setprocessing_fee_collection]=useState();
 const[insurance_collection,setinsurance_collection]=useState();
 const[loan_closure_collection,setloan_closure_collection]=useState();
 const[part_payment_collection,setpart_payment_collection]=useState();

 //EMIS DATA
 //total
 const[total_emis_count,settotal_emis_count]=useState();
 const[total_emis_amount,settotal_emis_amount]=useState();
 const[total_overdues_amount,settotal_overdues_amount]=useState();
 const[total_emis_total,settotal_emis_total]=useState();
 const[total_emis_principle,settotal_emis_principle]=useState();
 const[total_emis_interest,settotal_emis_interest]=useState();
 const[total_emis_gst,settotal_emis_gst]=useState();

  //paid
  const[paid_emis_count,setpaid_emis_count]=useState();
  const[paid_emis_amount,setpaid_emis_amount]=useState();
  const[paid_overdues_amount,setpaid_overdues_amount]=useState();
  const[paid_emis_total,setpaid_emis_total]=useState();
  const[paid_emis_principle,setpaid_emis_principle]=useState();
  const[paid_emis_interest,setpaid_emis_interest]=useState();
  const[paid_emis_gst,setpaid_emis_gst]=useState();
  
  //due
  const[due_emis_count,setdue_emis_count]=useState();
  const[due_emis_amount,setdue_emis_amount]=useState();
  const[due_overdues_amount,setdue_overdues_amount]=useState();
  const[due_emis_total,setdue_emis_total]=useState();
  const[due_emis_principle,setdue_emis_principle]=useState();
  const[due_emis_interest,setdue_emis_interest]=useState();
  const[due_emis_gst,setdue_emis_gst]=useState();

 //OTHER ENTRIES
 const[income_by_employee,setincome_by_employee]=useState();
 const[expense_by_employee,setexpense_by_employee]=useState();
 const[income_by_admin,setincome_by_admin]=useState();
 const[expense_by_admin,setexpense_by_admin]=useState();
 const[cash_entry_by_employee,setcash_entry_by_employee]=useState();
 const[cash_drawings_by_employee,setcash_drawings_by_employee]=useState();
 const[cash_entry_by_admin,setcash_entry_by_admin]=useState();
 const[cash_drawings_by_admin,setcash_drawings_by_admin]=useState();
 const[employee_salary_paid,setemployee_salary_paid]=useState();

 //GST LIABILITY
 const[insurance_gst_amount,setinsurance_gst_amount]=useState();
 const[emi_gst_amount,setemi_gst_amount]=useState();
 const[total_gst_liability,settotal_gst_liability]=useState();

 //
 const[pfcollection_loan_applications,setpfcollection_loan_applications]=useState();

 //BALANE SHEET
 const[pl_total_income,setpl_total_income]=useState();
 const[pl_total_expense,setpl_total_expense]=useState();
 const[pl_total_left,setpl_total_left]=useState();
 const[pl_total_right,setpl_total_right]=useState();
 const[pl_total,setpl_total]=useState();
 const[profit,setprofit]=useState();
 const[loss,setloss]=useState();

 //OPENING AND CLOSING BALANCE
 const[cb_opening_cash,setcb_opening_cash]=useState();
 const[bb_opening_bank,setbb_opening_bank]=useState();
 const[opening_cash,setopening_cash]=useState();
 const[cb_closing_cash,setcb_closing_cash]=useState();
 const[bb_closing_bank,setbb_closing_bank]=useState();
 const[closing_cash,setclosing_cash]=useState();

//Header
const[haddress,sethaddress]=useState(sessionStorage.getItem("headerAddress00"));const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState(sessionStorage.getItem("headerCity00"));const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState(sessionStorage.getItem("headerState00"));const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState(sessionStorage.getItem("headerPincode00"));const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState(sessionStorage.getItem("headerEmail00"));const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState(sessionStorage.getItem("headerContact00"));const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();

//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);
const[filter_group_name,setfilter_group_name]=useState();const updatefilter_group_name = (event) => { setfilter_group_name(event.target.value);}; const[filter_group_name_er,setfilter_group_name_er]=useState();

const[start_date,setstart_date] = useState('1970-01-01'); const updatestart_date = (event) => { setstart_date(event.target.value);}; const[start_date_er,setstart_date_er]=useState();
const[end_date,setend_date] = useState('2100-12-12'); const updateend_date = (event) => { setend_date(event.target.value);}; const[end_date_er,setfilter_end_date_er]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW LOAN PART PAYMENT FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))/*&&(branch_id>0)*/){
 
      //Fetching Filter Variables
      const $start_date = start_date;const $end_date = end_date;

 axios.post("crm-employee-get-final-summary", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id,
   Filter_val:0,
   Filter_start_date : moment($start_date).format('YYYY-MM-DD'),
   Filter_end_date : moment($end_date).format('YYYY-MM-DD'),


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg(response.data.error_msg);}

  //Success Data Fetched
  if(response.data.successvar==1){

   // console.log(response.data);
   //Setting Data

   //OVERALL DATA
   setcustomers_count(response.data.customers_count);
   setemployees_count(response.data.employees_count);
   setbranches_count(response.data.branches_count);
   setloan_schemes_count(response.data.loan_schemes_count);
   setloan_groups_count(response.data.loan_groups_count);
   settransactions_count(response.data.transactions_count);

   //CASHFLOW DATA
   setcash_inflow(response.data.cash_inflow);
   setcash_outflow(response.data.cash_outflow);
   setbank_inflow(response.data.bank_inflow);
   setbank_outflow(response.data.bank_outflow);
   settotal_cash_inflow(response.data.total_cash_inflow);
   settotal_cash_outflow(response.data.total_cash_outflow);

   //LOANS
   settotal_loans_count(response.data.total_loans_count);
   setapproved_loans_count(response.data.approved_loans_count);
   setcompleted_loans_count(response.data.completed_loans_count);
   settotal_disbursements_count(response.data.total_disbursements_count);
   settotal_disbursements_amount(response.data.total_disbursements_amount);
   setprocessing_fee_collection(response.data.processing_fee_collection);
   setinsurance_collection(response.data.insurance_collection);
   setloan_closure_collection(response.data.loan_closure_collection);
   setpart_payment_collection(response.data.part_payment_collection);

   //EMIS DATA
   //total
   settotal_emis_count(response.data.total_emis_count);
   settotal_emis_amount(response.data.total_emis_amount);
   settotal_overdues_amount(response.data.total_overdues_amount);
   settotal_emis_total(response.data.total_emis_total);
   settotal_emis_principle(response.data.total_emis_principle);
   settotal_emis_interest(response.data.total_emis_interest);
   settotal_emis_gst(response.data.total_emis_gst);
   //paid
   setpaid_emis_count(response.data.paid_emis_count);
   setpaid_emis_amount(response.data.paid_emis_amount);
   setpaid_overdues_amount(response.data.paid_overdues_amount);
   setpaid_emis_total(response.data.paid_emis_total);
   setpaid_emis_principle(response.data.paid_emis_principle);
   setpaid_emis_interest(response.data.paid_emis_interest);
   setpaid_emis_gst(response.data.paid_emis_gst);
   //due
   setdue_emis_count(response.data.due_emis_count);
   setdue_emis_amount(response.data.due_emis_amount);
   setdue_overdues_amount(response.data.due_overdues_amount);
   setdue_emis_total(response.data.due_emis_total);
   setdue_emis_principle(response.data.due_emis_principle);
   setdue_emis_interest(response.data.due_emis_interest);
   setdue_emis_gst(response.data.due_emis_gst);

   //OTHER ENTRIES
   setincome_by_employee(response.data.income_by_employee);
   setexpense_by_employee(response.data.expense_by_employee);
   setincome_by_admin(response.data.income_by_admin);
   setexpense_by_admin(response.data.expense_by_admin);
   setcash_entry_by_employee(response.data.cash_entry_by_employee);
   setcash_drawings_by_employee(response.data.cash_drawings_by_employee);
   setcash_entry_by_admin(response.data.cash_entry_by_admin);
   setcash_drawings_by_admin(response.data.cash_drawings_by_admin);
   setemployee_salary_paid(response.data.employee_salary_paid);

   //GST LIABILITY
   setinsurance_gst_amount(response.data.insurance_gst_amount);
   setemi_gst_amount(response.data.emi_gst_amount);
   settotal_gst_liability(response.data.total_gst_liability);

   //BALANCE SHEET
   setpl_total_income(response.data.pl_total_income);
   setpl_total_expense(response.data.pl_total_expense);
   setpl_total_left(response.data.pl_total_left);
   setpl_total_right(response.data.pl_total_right);
   setpl_total(response.data.pl_total);
   setprofit(response.data.profit);
   setloss(response.data.loss);

   //OPENING AND CLOSING BALANCE
   setcb_opening_cash(response.data.cb_opening_cash);
   setbb_opening_bank(response.data.bb_opening_bank);
   setopening_cash(response.data.opening_cash);
   setcb_closing_cash(response.data.cb_closing_cash);
   setbb_closing_bank(response.data.bb_closing_bank);
   setclosing_cash(response.data.closing_cash);

   //
   setpfcollection_loan_applications(response.data.pfcollection_loan_applications);

   //setstart_date=(response.data.Filter_start_date);
   //setend_date_er(response.data.Filter_end_date);
   

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/"); 
   //console.log(response.data);
 
  }
 
 }
  
 //******************* VIEW LOAN PART PAYMENT FUNCTION END  ********************//


 
return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

           

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/adminpanel-dashboard-customers")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">SUMMARY</p>
                  </a>
                </div>
            
              </div>
            </div>

            <button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
            </button>
            <br/><br/>


                        {/* FILTER SECTION START */}
                        <hr/>
                        <div class="card">   
                        <div class="col-12 mt-2" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                        {/*}  <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button> */}
                          
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                           
                          </div>
                          </div>
                        
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}


  
   <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
   <div class="d-flex justify-content-between">
   <div>
   <h4 style={{padding:'1rem'}}>Filters</h4>
   <code>{error_msg}</code>
  </div>
  <div>

  <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
  <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
  </button>
  </div>
  </div>

<div class="row" style={{padding:"1rem"}}>
 <div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Start Date</label>
   <div class="col-sm-8">
     <input type="date" class="form-control" placeholder="Group A"   id="start_date" value={start_date} onChange={updatestart_date}  />
     <code>{start_date_er}</code>
   </div>
 </div>
 </div>
 <div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">End Date</label>
   <div class="col-sm-8">
     <input type="date" class="form-control" placeholder="Group A"   id="end_date" value={end_date} onChange={updateend_date}  />
     <code>{end_date_er}</code>
   </div>
 </div>
 </div>
 <div class="col-md-4">
   <button class="btn btn-primary" onClick={()=>{ setfilterval(1); getData(); }}>Apply Filters</button>
 </div>



</div>
</div>
  </div>


   <hr/>                  
   </div> 
   
 {/*}   FILTER SECTION END */}

                <div class="card">

                  {/* Print Section Start */}
                  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>

                      {/* PART PAYMENT REQUEST HEADER START */}
                      <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    {/* PART PAYMENT REQUEST HEADER END */}
                    
                      <h2 style={{textAlign:"center", marginTop:"2rem"}}>SUMMARY</h2>
                      <hr/>
              
                   <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">OVERALL DATA SUMMARIES</h3>
                {/*}    <div class="form-sample" style={{textAlign:"left"}}>

                   <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customers:</b></p></div>
                    <div class="row col-sm-6"> <p>{customers_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Employees:</b></p></div>
                    <div class="row col-sm-6"> <p>{employees_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Branches:</b></p></div>
                    <div class="row col-sm-6"> <p>{branches_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loan Schemes:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_schemes_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loan Groups:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_groups_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Transactions:</b></p></div>
                    <div class="row col-sm-6"> <p>{transactions_count}</p></div>
                    </div>

                    </div>
                    </div>
                    <hr/>  */}

                
                <h3 class="card-title">CASH SUMMARY</h3>
                <div class="form-sample" style={{textAlign:"left"}}>

                <div class="row" style={{textAlign:"left"}}>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Opening Cash Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{cb_opening_cash}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Opening Bank Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{bb_opening_bank}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Opening Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{opening_cash}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Closing Cash Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{cb_closing_cash}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Closing Bank Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{bb_closing_bank}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Closing Bal.:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{closing_cash}</p></div>
                </div>
                </div>
                </div>
                <hr/>
                <h3 class="card-title">CASHFLOW</h3>
                <div class="form-sample" style={{textAlign:"left"}}>
                <div class="row" style={{textAlign:"left"}}>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Cash Inflow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{cash_inflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Bank Cash Inflow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{bank_inflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Total Cash Inflow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{total_cash_inflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Cash OutFlow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{cash_outflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Bank Cash  OutFlow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{bank_outflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Total Cash Outflow:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{total_cash_outflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Cash Balance:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{cash_inflow-cash_outflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Bank Cash Balance:</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{bank_inflow-bank_outflow}</p></div>
                </div>
                <div class="row col-md-4">
                <div class="row col-sm-6"> <p><b>Total Cash Balance</b></p></div>
                <div class="row col-sm-6"> <p><b> ₹ </b>{total_cash_inflow-total_cash_outflow}</p></div>
                </div>
            

                </div>
                </div>

                <hr/>

         {/*}           <h3 class="card-title">LOANS SUMMARY</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>

                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total Loans:</b></p></div>
                    <div class="row col-sm-6"> <p>{total_loans_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Approved Loans:</b></p></div>
                    <div class="row col-sm-6"> <p>{approved_loans_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Completed Loans:</b></p></div>
                    <div class="row col-sm-6"> <p>{completed_loans_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loans Disbursed:</b></p></div>
                    <div class="row col-sm-6"> <p>{total_disbursements_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Disbursed Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_disbursements_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Processing Fee Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{processing_fee_collection}</p></div> 
                 {/*}  <div class="row col-sm-6"> <p>{pfcollection_loan_applications}</p></div> 
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Insurance Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{insurance_collection}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loan Closures Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{loan_closure_collection}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Part Payments Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{part_payment_collection}</p></div>
                    </div>

                    </div>
                    </div>


                   <hr/>
                   <h3 class="card-title">EMIS SUMMARY</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>

                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total EMIs:</b></p></div>
                    <div class="row col-sm-6"> <p>{total_emis_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid EMIs:</b></p></div>
                    <div class="row col-sm-6"> <p>{paid_emis_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due EMIs:</b></p></div>
                    <div class="row col-sm-6"> <p>{due_emis_count}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total EMIs Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_emis_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid EMIs Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_emis_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due EMIs Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_emis_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total Overdues Charges:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_overdues_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid Overdues Charges:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_overdues_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due Overdues Charges:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_overdues_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total Receivable Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_emis_total}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid Receivable Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_emis_total}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due Receivable Amount:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_emis_total}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total EMIs Principle:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_emis_principle}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid EMIs Principle:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_emis_principle}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due EMIs Principle:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_emis_principle}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total EMIs Interest:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_emis_interest}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid EMIs Interest:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_emis_interest}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due EMIs Interest:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_emis_interest}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total EMIs GST:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_emis_gst}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Paid EMIs GST:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{paid_emis_gst}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Due EMIs GST:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{due_emis_gst}</p></div>
                    </div>
                    

                    </div>
                    </div>

                    
                    <hr/>
                    <h3 class="card-title">OTHER ENTRIES</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>

                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Expenses By Employee:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{expense_by_employee}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Expenses By Admin:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{expense_by_admin}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Incomes By Employee:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{income_by_employee}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Incomes By Admin:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{income_by_admin}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Cash Entry By Admin:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{cash_entry_by_admin}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Cash Drawings By Admin:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{cash_drawings_by_admin}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Cash Entry By Employee:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{cash_entry_by_employee}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Cash Drawings By Employee:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{cash_drawings_by_employee}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Employee Salaries:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{employee_salary_paid}</p></div>
                    </div>
                   

                    </div>
                    </div>

                    <hr/>
                    <h3 class="card-title">GST LIABILITY</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>

                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Insurance GST Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{insurance_gst_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>EMI GST Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{emi_gst_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total GST Collected:</b></p></div>
                    <div class="row col-sm-6"> <p><b> ₹ </b>{total_gst_liability}</p></div>
                    </div>

                    </div>
                    </div> */}
                    </div>

                  {/*}  <hr/>
                    <h3 class="card-title">PROFIT LOSS CALCULATION (OVERALL TIME PERIOD)</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>

                   <div class="row" style={{textAlign:"left"}}>
                   <div class="table-responsive">
                    <table class="table table-bordered">
                    <thead>
                     <tr>
                      <td><b>Particular (Incomes)</b></td>
                      <td><b>Amount (Incomes)</b></td>
                      <td><b>Particular (Expenses)</b></td>
                      <td><b>Amount (Expenses)</b></td>
                     </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Total Interest (view details in emi summary)*</td>
                        <td>{total_emis_interest}</td>
                        <td>Employee Salaries Paid (view in other entries)*</td>
                        <td>{employee_salary_paid}</td>
                      </tr>
                      <tr>
                        <td>OD Charges(view details in emi summary)*</td>
                        <td>{total_overdues_amount}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Processing Fee Collected</td>
                        <td>{processing_fee_collection}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Incomes</td>
                        <td>{pl_total_income}</td>
                        <td>Expenses</td>
                        <td>{pl_total_expense}</td>
                      </tr>
                      <tr>
                        <td>Loss</td>
                        <td>{loss}</td>
                        <td>Profit</td>
                        <td>{profit}</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td><b>{pl_total}</b></td>
                        <td><b>Total</b></td>
                        <td>{pl_total}</td>
                      </tr>
                    </tbody>
                    </table>
                   </div>
                   </div>
                   </div>
                   
                    
                    
                     <hr/>
                   {/*} <br/><br/><br/>
                    <div class="row col-md-12 d-print-flex">
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Customer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Loan Officer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Branch Seal & Signature</b></p></div>
                    </div> */}
                   <br/><br/>
                    </div>
                    </div>
                    

                
              
                 
              
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_finalanalysis;