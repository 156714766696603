import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_rdrequests from './create_newaccounts';



function Admin_rd_maturityamt_calculator(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 //SETTING DATA VARIABLES


 const[deposit_amount,setdeposit_amount]=useState();const updatedeposit_amount = (event) => { setdeposit_amount(event.target.value); calculate_principle(); }; const[deposit_amount_er,setdeposit_amount_er]=useState();
 const[interest_rate,setinterest_rate]=useState();const updateinterest_rate = (event) => { setinterest_rate(event.target.value);}; const[interest_rate_er,setinterest_rate_er]=useState();
 const[installments,setinstallments]=useState();const updateinstallments = (event) => { setinstallments(event.target.value); calculate_principle(); calculate_rddays(); }; const[installments_er,setinstallments_er]=useState();
 const[installment_amount,setinstallment_amount]=useState();const updateinstallment_amount = (event) => { setinstallment_amount(event.target.value); calculate_principle(); }; const[installment_amount_er,setinstallment_amount_er]=useState();
 const[principle_amount,setprinciple_amount]=useState();const updateprinciple_amount = (event) => { setprinciple_amount(event.target.value);}; const[principle_amount_er,setprinciple_amount_er]=useState();
 const[installment_frequency_indays,setinstallment_frequency_indays]=useState();const updateinstallment_frequency_indays = (event) => { setinstallment_frequency_indays(event.target.value);  calculate_rddays(); }; const[installment_frequency_indays_er,setdinstallment_frequency_indays_er]=useState();
 const[total_days,settotal_days]=useState();const updatetotal_days = (event) => { settotal_days(event.target.value);}; const[total_days_er,settotal_days_er]=useState();
 const[interest_amount,setinterest_amount]=useState();const updateinterest_amount = (event) => { setinterest_amount(event.target.value);}; const[interest_amount_er,setinterest_amount_er]=useState();
 const[total_amount,settotal_amount]=useState();const updatetotal_amount = (event) => { settotal_amount(event.target.value);}; const[total_amount_er,settotal_amount_er]=useState();
 const[total_principle,settotal_principle]=useState();const updatetotal_principle = (event) => { settotal_principle(event.target.value);}; const[total_principle_er,settotal_principle_er]=useState();
 const[total_interest_amount,settotal_interest_amount]=useState();const updatetotal_interest_amount = (event) => { settotal_interest_amount(event.target.value);}; const[total_interest_amount_er,settotal_interest_amount_er]=useState();
 const[maturity_amount,setmaturity_amount]=useState();const updatematurity_amount = (event) => { setmaturity_amount(event.target.value);}; const[maturity_amount_er,setmaturity_amount_er]=useState();
  
 const[interest_per_day,setinterest_per_day]=useState();
 const[interest_per_month,setinterest_per_month]=useState();

 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();



  //OTHER FUNCTIONS

  //calculate principle
  function calculate_principle(){
   const  installment_days_amt = parseFloat(installments)*parseFloat(installment_amount);
   const tprinciple = parseFloat(deposit_amount) + parseFloat(installment_days_amt);
   setprinciple_amount(tprinciple);

  }
  
  //calculate rd days
  function calculate_rddays(){
    const rd_days = parseFloat(installments)*parseFloat(installment_frequency_indays);
    settotal_days(rd_days);
  }
  
/***************** CALCULATE RD MATURITY AMOUNT FUNCTION START  ******************/
/*function calculate_maturity_amt(){

  calculate_principle(); calculate_rddays();

  //calculation of interest
  const $deposit_amount = parseFloat(deposit_amount);
  const $interest_rate = parseFloat(interest_rate);
  const $installments = parseFloat(installments);
  const $installment_frequency_indays = parseFloat(installment_frequency_indays);
  const $installment_amount = parseFloat(installment_amount);
  const $interest_amount = 0;
  const $principle_amount = $deposit_amount;
  const $interest_per_month = ($interest_rate/12).toFixed(3);
  const $interest_per_day = ($interest_per_month/30).toFixed(3);

  let $updated_installments = $installments;
  let $id = 1; 
  let $updated_installment_amount = 0;
  let $total_amount = $deposit_amount;
  let $total_days = $installments*$installment_frequency_indays;
  let $updated_interest_amount = 0;

  setinterest_per_day($interest_per_day);
  setinterest_per_month($interest_per_month);


  while($updated_installments>0){

  // Table reference
  const table = document.getElementById('rd-table').getElementsByTagName('tbody')[0];

  // Add a new row
  const newRow = table.insertRow();

  // Add cells to the row
  newRow.innerHTML = `
      <td>${$id}</td>
      <td>${$deposit_amount.toFixed(2)}</td>
      <td>${$updated_installment_amount.toFixed(2)}</td>
      <td>${$principle_amount.toFixed(2)}</td>
      <td>${$updated_interest_amount.toFixed(2)}</td>
      <td>${$total_days.toFixed(2)}</td>
      <td>${$total_amount.toFixed(2)}</td>
  `;

    $updated_installments = $updated_installments-1;
    $updated_installment_amount = $updated_installment_amount + $installment_amount;
    $principle_amount = $principle_amount + $installment_amount;
    $updated_interest_amount = $principle_amount * $interest_per_day * $installment_frequency_indays/100;
    $total_amount = $principle_amount + $interest_amount;
    $id = $id-1;

  }

  //let $updated_installments = parseFloat()
  
 

     

} */



/***************** CALCULATE RD MATURITY AMOUNT FUNCTION END  ******************/

  
/*function calculate_maturity_amt() {
  calculate_principle();
  calculate_rddays();

  // Define and parse input variables
  const deposit_amount = parseFloat(document.getElementById('deposit_amount').value || 0);
  const interest_rate = parseFloat(document.getElementById('interest_rate').value || 0);
  const installments = parseFloat(document.getElementById('installments').value || 0);
  const installment_frequency_indays = parseFloat(document.getElementById('installment_frequency_indays').value || 0);
  const installment_amount = parseFloat(document.getElementById('installment_amount').value || 0);

  // Calculate interest rates
  const interest_per_month = interest_rate / 12;
  const interest_per_day = interest_per_month / 30;

  // Update helper functions
  setinterest_per_day(interest_per_day.toFixed(3));
  setinterest_per_month(interest_per_month.toFixed(3));

  // Initialize variables
  let updated_installments = installments;
  let id = 1;
  let updated_installment_amount = 0;
  let principle_amount = deposit_amount;
  let total_amount = deposit_amount;
  let total_days = installments * installment_frequency_indays;
  let updated_interest_amount = 0;

  // Table reference
  const table = document.getElementById('rd-table').getElementsByTagName('tbody')[0];

  // Loop to calculate and insert rows
  while (updated_installments >= 0) {
      // Calculate updated values
      updated_interest_amount = principle_amount * interest_per_day * installment_frequency_indays / 100;
      total_amount = principle_amount + updated_interest_amount;

      // Add a new row to the table
      const newRow = table.insertRow();
      newRow.innerHTML = `
          <td>${id}</td>
          <td>${deposit_amount.toFixed(2)}</td>
          <td>${updated_installment_amount.toFixed(2)}</td>
          <td>${principle_amount.toFixed(2)}</td>
          <td>${updated_interest_amount.toFixed(2)}</td>
          <td>${total_days.toFixed(2)}</td>
          <td>${total_amount.toFixed(2)}</td>
      `;

      // Update variables
      updated_installments--;
      updated_installment_amount += installment_amount;
      principle_amount += installment_amount;
      id++;
  }
} */


function calculate_maturity_amt() {
  calculate_principle();
  calculate_rddays();

  // Define and parse input variables
  const deposit_amount = parseFloat(document.getElementById('deposit_amount').value || 0);
  const interest_rate = parseFloat(document.getElementById('interest_rate').value || 0);
  const installments = parseFloat(document.getElementById('installments').value || 0);
  const installment_frequency_indays = parseFloat(document.getElementById('installment_frequency_indays').value || 0);
  const installment_amount = parseFloat(document.getElementById('installment_amount').value || 0);

  // Calculate interest rates
  const interest_per_month = interest_rate / 12;
  const interest_per_day = interest_per_month / 30;

  // Update helper functions
  setinterest_per_day(interest_per_day.toFixed(3));
  setinterest_per_month(interest_per_month.toFixed(3));

  // Initialize variables
  let updated_installments = installments+1;
  let id = 0;
  let principle_amount = deposit_amount;
  let total_amount = deposit_amount;
  let total_days = installments * installment_frequency_indays;
  let cumulative_interest = 0; // Total interest accumulator
  let updated_days = 0;

  // Table reference
  const table = document.getElementById('rd-table').getElementsByTagName('tbody')[0];

  // Clear existing rows in case of re-calculation
  table.innerHTML = '';

  // Loop to calculate and insert rows
  while (updated_installments > 0) {
      // Calculate interest for this installment
      let interest_for_installment = principle_amount * interest_per_day * installment_frequency_indays / 100;
      cumulative_interest += interest_for_installment; // Update cumulative interest
      total_amount = principle_amount + cumulative_interest;
      updated_days = updated_days + installment_frequency_indays;

      // Add a new row to the table
      const newRow = table.insertRow();
      newRow.innerHTML = `
          <td>${id}</td>
          <td>${deposit_amount.toFixed(2)}</td>
          <td>${installment_amount.toFixed(2)}</td>
          <td>${principle_amount.toFixed(2)}</td>
          <td>${interest_for_installment.toFixed(2)}</td>
          <td>${cumulative_interest.toFixed(2)}</td>
          <td>${updated_days.toFixed(2)}</td>
          <td>${total_amount.toFixed(2)}</td>
      `;

      // Update variables for the next iteration
      principle_amount += installment_amount;
      updated_installments--;
      id++;
  }

  settotal_interest_amount(cumulative_interest.toFixed(2));
  settotal_principle(principle_amount-installment_amount.toFixed(2));
  setmaturity_amount(total_amount.toFixed(2));
}



return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">



          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-rd-requests")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">RECURRING DEPOSIT (RD) CALCULATOR</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Recurring Deposit (RD) Maturity Amount Calculator</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="deposit_amount" value={deposit_amount} onChange={updatedeposit_amount} />
                              <code>{deposit_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installments<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="20" id="installments" value={installments} onChange={updateinstallments} />
                              <code>{installments_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest %<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="interest_rate" value={interest_rate} onChange={updateinterest_rate} />
                              <code>{interest_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500" id="installment_amount" value={installment_amount} onChange={updateinstallment_amount} />
                              <code>{installment_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Principle Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="principle_amount" value={principle_amount} onChange={updateprinciple_amount} />
                              <code>{principle_amount_er}</code>
                            </div>
                          </div>
                        </div>
                 {/*}       <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Frequency (in days)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1095" id="installment_frequency_indays" value={installment_frequency_indays} onChange={updateinstallment_frequency_indays} />
                              <code>{installment_frequency_indays_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">No. of Installments<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="installments" value={installments} onChange={updateinstallments} />
                              <code>{installments_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Frequency (in days)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10" id="installment_frequency_indays" value={installment_frequency_indays} onChange={updateinstallment_frequency_indays} />
                              <code>{installment_frequency_indays_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Days</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="total_days" value={total_days} onChange={updatetotal_days} />
                              <code>{total_days_er}</code>
                            </div>
                          </div> 
                        </div> 
                       {/*} <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1095" id="interest_amount" value={interest_amount} onChange={updateinterest_amount} />
                              <code>{interest_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="total_amount" value={total_amount} onChange={updatetotal_amount} />
                              <code>{total_amount_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Principle Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="25000.00" id="total_principle" value={total_principle} onChange={updatetotal_principle} />
                              <code>{total_principle_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Total Interest Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="total_interest_amount" value={total_interest_amount} onChange={updatetotal_interest_amount} />
                              <code>{total_interest_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maturity Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50000.00" id="maturity_amount" value={maturity_amount} onChange={updatematurity_amount} />
                              <code>{maturity_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>                     
                      {/*}  <div class="form-group">
                        <label for="exampleTextarea1">Details<code>*</code></label>
                        <textarea  class="form-control" rows="2" placeholder="here details" id="details" value={details} onChange={updatedetails} />
                        <code>{details_er}</code>
                        </div> */}

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={calculate_maturity_amt}>Calculate</button>

                        <p class="text-muted" style={{textAlign:"right", fontSize:"0.7rem"}}>this is estimated maturity amount calculation, actual amount and calculation may differ. *</p>

                        <hr/>


                      <p>interest per day = {interest_per_day} %</p>
                      <p>interest per month = {interest_per_month} %</p>

                      <div clss="table-responsive">
                        <table class="table table-bordered" id="rd-table">
                        <thead>
                     {/*}   <tr>
                           <th>ID</th>
                           <th>Primary Deposit</th>
                           <th>Additional Deposit</th>
                           <th>Principle</th>
                           <th>Interest</th>
                           <th>Days</th>
                           <th>Total</th>
                        </tr> */}
      <tr><th>Installment #</th>
      <th>Deposit Amount</th>
      <th>Installment Amount</th>
      <th>Principal Amount</th>
      <th>Interest for Installment</th>
      <th>Total Interest</th>
      <th>Total Days</th>
      <th>Total Amount</th></tr>
                        </thead>
                        <tbody>
                        </tbody>
                        </table>
                      </div>


                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_rd_maturityamt_calculator;