import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_rdrequests from './create_newaccounts';



function Admin_rd_scheme_details(){

  
    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");


 
 //SETTING DATA VARIABLES
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[type,settype]=useState();
 const[installments,setinstallments]=useState();
 const[installment_frequency_indays,setinstallment_frequency_indays]=useState();
 const[minimum_total_amount,setminimum_total_amount]=useState();
 const[maximum_total_amount,setmaximum_total_amount]=useState();
 const[duration_inmonths,setduration_inmonths]=useState();
 const[duration_indays,setduration_indays]=useState();
 const[maturity_yearly_interest_rate,setmaturity_yearly_interest_rate]=useState();
 const[maturity_monthly_interest_rate,setmaturity_monthly_interest_rate]=useState();
 const[maturity_daily_interest_rate,setmaturity_daily_interest_rate]=useState();
 const[prematurity_yearly_interest_rate,setprematurity_yearly_interest_rate]=useState();
 const[prematurity_monthly_interest_rate,setprematurity_monthly_interest_rate]=useState();
 const[prematurity_daily_interest_rate,setprematurity_daily_interest_rate]=useState();
 const[maximum_deposit,setmaximum_deposit]=useState();
 const[minimum_deposit,setminimum_deposit]=useState();
 const[customers_limit,setcustomers_limit]=useState();
 const[scheme_status,setscheme_status]=useState();
 const[details,setdetails]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  
  // gdrive data
  const[gdrive_data,setgdrive_data]=useState([],);

 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* GET SCHEME DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-get-rd-scheme-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Scheme Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setscheme_name(response.data.scheme_name);
   setscheme_code(response.data.scheme_code);
   settype(response.data.type);
   setinstallments(response.data.installments);
   setinstallment_frequency_indays(response.data.installment_frequency_indays);
   setminimum_total_amount(response.data.minimum_total_amount);
   setmaximum_total_amount(response.data.maximum_total_amount);
   //setduration_inmonths(response.data.duration_inmonths);
   setduration_indays(response.data.duration_indays);
   setmaturity_yearly_interest_rate(response.data.maturity_yearly_interest_rate);
   setmaturity_monthly_interest_rate(response.data.maturity_monthly_interest_rate);
   setmaturity_daily_interest_rate(response.data.maturity_daily_interest_rate);
   setprematurity_yearly_interest_rate(response.data.prematurity_yearly_interest_rate);
   setprematurity_monthly_interest_rate(response.data.prematurity_monthly_interest_rate);
   setprematurity_daily_interest_rate(response.data.prematurity_daily_interest_rate);
   setmaximum_deposit(response.data.maximum_deposit);
   setminimum_deposit(response.data.minimum_deposit);
   setcustomers_limit(response.data.customers_limit);
   setdetails(response.data.details);
   setscheme_status(response.data.scheme_status);
   
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET SCHEME DATA FUNCTION END  ********************//

 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-rd-schemes")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">Recurring Deposit (RD) SCHEME DETAILS</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">Recurring Deposit (RD) SCHEME DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>
                  
                    <div class="col-md-12">
                    <div class="row" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h3><b>Scheme Name: </b></h3></div>
                    <div class="row col-sm-6"><h3> {scheme_name}</h3></div>
                    </div>
                      
                      <hr/>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Scheme Code: </b></h4></div>
                    <div class="row col-sm-6"><h4>{scheme_code}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Type: </b></h4></div>
                    <div class="row col-sm-6"><h4>{type}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Minimum Total Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{minimum_total_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Maximum Total Amount: </b></h4></div>
                    <div class="row col-sm-6"><h4>{maximum_total_amount}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Installments: </b></h4></div>
                    <div class="row col-sm-6"><h4>{installments}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Installment Frequency (in days): </b></h4></div>
                    <div class="row col-sm-6"><h4>{installment_frequency_indays}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Duration (in days): </b></h4></div>
                    <div class="row col-sm-6"><h4>{duration_indays}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Maturity Interest Rate (yearly ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{maturity_yearly_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Maturity Interest Rate (monthly ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{maturity_monthly_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Maturity Interest Rate (daily ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{maturity_daily_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Pre-maturity Interest Rate (yearly ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{prematurity_yearly_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Pre-maturity Interest Rate (monthly ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{prematurity_monthly_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Pre-maturity Interest Rate (daily ): </b></h4></div>
                    <div class="row col-sm-6"><h4>{prematurity_daily_interest_rate} %</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Maximum Deposit: </b></h4></div>
                    <div class="row col-sm-6"><h4>{maximum_deposit}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Minimum Deposit: </b></h4></div>
                    <div class="row col-sm-6"><h4>{minimum_deposit}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Customers Limit: </b></h4></div>
                    <div class="row col-sm-6"><h4>{customers_limit}</h4></div>
                    </div>
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><h4><b>Status</b></h4></div>
                    <div class="row col-sm-6"><div><label className="badge badge-success" hidden={scheme_status=="inactive"} >active</label><label className="badge badge-danger" hidden={scheme_status=="active"} >active</label></div></div>
                    </div>
                 </div>
                 </div>
                  <hr/>
                  <h3><b>Scheme Details</b></h3>
                  <p>{details}</p>

                    
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_rd_scheme_details;