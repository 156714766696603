import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_fdrequests from './create_newaccounts';
import { useLocation } from 'react-router-dom';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Admin_view_fd_withdrawal(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  

    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //SETTING DATA VARIABLES
 const[application_no,setapplication_no]=useState();
 const[tr_no,settr_no]=useState();
 const[fd_id,setfd_id]=useState();
 const[fd_number,setfd_number]=useState();
 const[branch_code,setbranch_code]=useState();
 const[group_name,setgroup_name]=useState();
 const[account_number,setaccount_number]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[adr_number,setadr_number]=useState();
 const[date_of_birth,setdate_of_birth]=useState();
 const[gender,setgender]=useState();
 const[address,setaddress]=useState();
 const[city,setcity]=useState();
 const[cstate,setcstate]=useState();
 const[pincode,setpincode]=useState();
 const[customer_image_path,setcustomer_image_path]=useState();
 const[withdrawal_status,setwithdrawal_status]=useState();
 const[withdrawal_type,setwithdrawal_type]=useState();
 const[wd_type,setwd_type]=useState();
 const[applicable_yearly_interest_rate,setapplicable_yearly_interest_rate]=useState();
 const[applicable_monthly_interest_rate,setapplicable_monthly_interest_rate]=useState();
 const[applicable_daily_interest_rate,setapplicable_daily_interest_rate]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[duration_indays,setduration_indays]=useState();
 const[duration_inmonths,setduration_inmonths]=useState();
 const[duration_indays_completed,setduration_indays_completed]=useState();
 const[duration_indays_shortfall,setduration_indays_shortfall]=useState();
 const[deposit_amount,setdeposit_amount]=useState();
 const[interest_fordays,setinterest_fordays]=useState();
 const[interest_amount,setinterest_amount]=useState();
 const[amount,setamount]=useState();
 const[processing_fee_per,setprocessing_fee_per]=useState();
 const[processing_fee_amount,setprocessing_fee_amount]=useState();
 const[total,settotal]=useState();
 const[maturity_amount,setmaturity_amount]=useState();
 const[deposit_date,setdeposit_date]=useState();
 const[maturity_date,setmaturity_date]=useState();
 const[mode,setmode]=useState();
 const[bank_id,setbank_id]=useState();
 const[bank_name,setbank_name]=useState();
 const[withdrawal_time,setwithdrawal_time]=useState();
 const[withdrawal_date,setwithdrawal_date]=useState();
 const[withdrawee_name,setwithdrawee_name]=useState();
 const[withdrawee_contact,setwithdrawee_contact]=useState();
 const[withdrawee_email,setwithdrawee_email]=useState();
 const[withdrawee_adr_number,setwithdrawee_adr_number]=useState();
 const[withdrawal_remarks,setwithdrawal_remarks]=useState();
 const[document_attachment,setdocument_attachment]=useState();
 const[date_created,setdate_created]=useState();
 const[time_created,settime_created]=useState();
 const[created_by,setcreated_by]=useState();



//Header
const[haddress,sethaddress]=useState(sessionStorage.getItem("headerAddress00"));const updatehaddress = (event) => { sethaddress(event.target.value);}; const[haddress_er,sethaddress_er]=useState();
const[hcity,sethcity]=useState(sessionStorage.getItem("headerCity00"));const updatehcity = (event) => { sethcity(event.target.value);}; const[hcity_er,sethcity_er]=useState();
const[hstate,sethstate]=useState(sessionStorage.getItem("headerState00"));const updatehstate = (event) => { sethstate(event.target.value);}; const[hstate_er,sethstate_er]=useState();
const[hpincode,sethpincode]=useState(sessionStorage.getItem("headerPincode00"));const updatehpincode = (event) => { sethpincode(event.target.value);}; const[hpincode_er,sethpincode_er]=useState();
const[hemail,sethemail]=useState(sessionStorage.getItem("headerEmail00"));const updatehemail = (event) => { sethemail(event.target.value);}; const[hemail_er,sethemail_er]=useState();
const[hcontact,sethcontact]=useState(sessionStorage.getItem("headerContact00"));const updatehcontact = (event) => { sethcontact(event.target.value);}; const[hcontact_er,sethcontact_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();

  
  // gdrive data
  const[gdrive_data,setgdrive_data]=useState([],);


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW LOAN APPLICATION FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-admin-view-fd-withdrawal-application", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setapplication_no(response.data.application_no);
   settr_no(response.data.tr_no);
   setfd_id(response.data.fd_id);
   setfd_number(response.data.fd_number);
   setbranch_code(response.data.branch_code);
   setgroup_name(response.data.group_name);
   setaccount_number(response.data.account_number);
   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setcustomer_id(response.data.customer_id);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setemail(response.data.email);
   setadr_number(response.data.adr_number);
   setdate_of_birth(response.data.date_of_birth);
   setgender(response.data.gender);
   setaddress(response.data.address);
   setcity(response.data.city);
   setcstate(response.data.state);
   setpincode(response.data.pincode);
   setcustomer_image_path((response.data.customer_image_path).slice(7));
   setwithdrawal_status(response.data.withdrawal_status);
   setwithdrawal_type(response.data.withdrawal_type);
   setwd_type(response.data.wd_type);
   setapplicable_yearly_interest_rate(response.data.applicable_yearly_interest_rate);
   setapplicable_monthly_interest_rate(response.data.applicable_monthly_interest_rate);
   setapplicable_daily_interest_rate(response.data.applicable_daily_interest_rate);
   setduration_indays(response.data.duration_indays);
   setduration_inmonths(response.data.duration_inmonths);
   setduration_indays_completed(response.data.duration_indays_completed);
   setduration_indays_shortfall(response.data.duration_indays_shortfall);
   setdeposit_amount(response.data.deposit_amount);
   setinterest_fordays(response.data.interest_fordays);
   setinterest_amount(response.data.interest_amount);
   setamount(response.data.amount);
   setprocessing_fee_per(response.data.processing_fee_per);
   setprocessing_fee_amount(response.data.processing_fee_amt);
   settotal(response.data.total);
   setmaturity_date(response.data.maturity_date);
   setdeposit_date(response.data.deposit_date);
   setmaturity_amount(response.data.maturity_amount);
   setmode(response.data.mode);
   setbank_id(response.data.bank_id);
   setbank_name(response.data.bank_name);
   setwithdrawal_date(response.data.withdrawal_date);
   setwithdrawal_time(response.data.withdrawal_time);
   setwithdrawee_name(response.data.withdrawee_name);
   setwithdrawee_contact(response.data.withdrawee_contact);
   setwithdrawee_email(response.data.withdrawee_email);
   setwithdrawee_adr_number(response.data.withdrawee_adr_number);
   setwithdrawal_remarks(response.data.withdrawal_remarks);
   setdocument_attachment((response.data.document_attachment).slice(7));
   setdate_created(response.data.date_created);
   settime_created(response.data.time_created);
   setcreated_by(response.data.created_by);




  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* VIEW LOAN APPLICATION FUNCTION END  ********************//


 
/***************** REJECT FD WITHDRAWAL APPLICATION FUNCTION START  ******************/
function reject_withdrawal_application(){

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)){
  
    //Sending Data through axios
    axios.post("crm-admin-reject-fd-withdrawal-application", { 
    Log_id:logid,
    Token:token,
    Edit_id:editId,
    
      }
      )
    
    .then((response) => {
  
  
    //Success
    if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }
    //Error
    else{
      document.getElementById("error-card").style.display="block";
    }
  
    //Session Expired
    if(response.data.successvar==100){ seterror_msg("Session Expired !");  }
  
  
    }    );
    
  }
    //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   
   window.location.replace("/");
   
   
   }    
  
  }
  
  /***************** REJECT FD WITHDRAWAL APPLICATION FUNCTION END  ******************/
  
  
 
 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">



     {/*  SUCCESS CARD START */}
     <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Fixed Deposit (FD) Withdrawal Request Rejected Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-fd-withdrawals"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
      </div>
      {/*  SUCCESS CARD END */}

      {/*  ERROR CARD START */}
     <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Reject Fixed Deposit (FD) Withdrawal Request !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-fd-withdrawals"><button class="mt-3 btn btn-outline-danger">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
      </div>
      {/*  ERROR CARD END */}

        

          <div className="content-wrapper pb-0">

          

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-fd-withdrawals")}}>  Back </button>
            </div>

            <br/>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">FIXED DEPOSIT (FD) WITHDRAWAL APPLICATION</p>
                  </a>
                </div>
                <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                sessionStorage.setItem("AppId00", fd_number);
                sessionStorage.setItem("EditId00", editId);
                sessionStorage.setItem("Date00", withdrawal_date);
                sessionStorage.setItem("Amt00", total);
                window.location.replace("/admin-dashboard-approve-fd-withdrawal");
              }}>
              
                  <i className="mdi mdi-plus-circle"></i>APPROVAL AND AMOUNT TRANSFER</button>  
                  <button type="button" style={{marginLeft:"1rem", backgroundColor:"#ee354f"}} class="btn btn-danger mt-2 mt-sm-0 btn-icon-text" onClick={reject_withdrawal_application}>
                  <i className="mdi mdi-minus-circle" style={{backgroundColor:"#bb0c25"}}></i>REJECT WITHDRAWAL REQUEST</button>  
          
        
              </div>
            </div>


          {/*}  <button class="btn btn-warning" onClick={()=>{
            document.getElementById("upd-header-card").style.display="block";
            }}>update header</button> */}
             <button type="button" style={{backgroundColor:"#196fb0", marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
             </button>
             


           {/* UPDATE HEADER INFO SECTION START 
            <div class="card" id="upd-header-card" style={{display:"none"}}>
              
              <div class="card-body" style={{textAlign:"left"}}>
                <h4 class="card-title">Update Header Information</h4>
                <div class="form-sample">
                  <p class="card-description">update header information</p>
                  
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Address</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="haddress" value={haddress} onChange={updatehaddress} />
                          <code>{haddress_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header City</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcity" value={hcity} onChange={updatehcity} />
                          <code>{hcity_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header State</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hstate" value={hstate} onChange={updatehstate} />
                          <code>{hstate_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Pincode</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hpincode" value={hpincode} onChange={updatehpincode} />
                          <code>{hpincode_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Email</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hemail" value={hemail} onChange={updatehemail} />
                          <code>{hemail_er}</code>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Header Contact</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" id="hcontact" value={hcontact} onChange={updatehcontact} />
                          <code>{hcontact_er}</code>
                        </div>
                      </div>
                    </div>
                    </div>

                  
                  
                   {/*} <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={()=>{
                       sethaddress(document.getElementById("haddress").value);
                       sethcity(document.getElementById("hcity").value);
                       sethstate(document.getElementById("hstate").value);
                       sethpincode(document.getElementById("hpincode").value);
                       sethemail(document.getElementById("hemail").value);
                       sethcontact(document.getElementById("hcontact").value);
                    }}>Update</button> */}

                 {/*}   <button class="btn btn-lg btn-outline-danger" style={{fontWeight:"bold"}} onClick={()=>{ 
                      document.getElementById("upd-header-card").style.display="none";
                    }}>
                      Close
                    </button>
                   

                 
               
                </div>
              </div>
            </div>
            {/* UPDATE HEADER INFO SECTION END */}

                <div class="card" style={{marginTop:"2rem"}}>
              
                  <div class="card-body" style={{textAlign:"left"}}>

                  {/* Print Section Start */}
                  <div class="" style={{padding:"1rem"}}  id="invoice" ref={componentRef}>
                  {/* SANCTION LETTER HEADER START */}


                  <div class="offer-letter-head" style={{borderBottom:"0.2rem solid #038d8e"}}>
                  <div style={{flexDirection:"row", display:"flex"}}>
                  <div style={{width:"25%"}} >
                    <img src="images/crmlogo.png" alt="profile" style={{width:"16rem", height:"16rem"}} />
                  </div>
                  <div class="row" style={{width:"75%"}}>

                    <div class="row col-md-12">
                    <h1 style={{color:"#038d8e", marginBottom:"0", textAlign:"center"}}><b>Pragati Prathmik Sakh Sahkari Samiti Maryadit</b></h1>
                    
                    <p style={{marginTop:"0", textAlign:"center"}}><b>Address:</b> {haddress} <br/>
                    {hcity} ({hstate}), ({hpincode}) <br/><br/>
                    <b>Email:</b> {hemail}, <b>Contact:</b> {hcontact}</p>

                  
                    </div>
                    </div>
                    </div>
                    </div>
                    
                    {/* SANCTION LETTER HEADER END */}
                    
                      <h2 style={{textAlign:"center", marginTop:"2rem"}}>Fixed Deposit (FD) Withdrawal Application</h2>
                      <hr/>
                      <div class="row d-print-flex mt-3">
                      <div class="row col-md-4 col d-print-inline-block"><b>Customer Image:</b></div>
                      <div class="row col-md-4 col d-print-inline-block"></div>
                      <div class="row col-md-4 col d-print-inline-block"><img style={{height:"18rem", width:"80%"}} src={imgpath+customer_image_path} /></div>
                      </div>

                      <h3 class="card-title">CUSTOMER DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row d-print-flex" style={{textAlign:"left"}}>

                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Id:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{contact}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Group Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{group_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Date of Birth:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{date_of_birth}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Gender:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{gender}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{account_number}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{branch_code}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-12">
                    <div class="row col-sm-3"> <p><b>Address:</b></p></div>
                    <div class="row col-sm-9" > <p>{address}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>City:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{city}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>State:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{cstate}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Pincode:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{pincode}</p></div>
                    </div>
                    </div>


                    <hr/>
                    <h3 class="card-title">WITHDRAWEE DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawee Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{withdrawee_name}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawee Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{withdrawee_contact}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawee Email:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{withdrawee_email}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawee Adr. Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>XXXXXXXX{withdrawee_adr_number}</p></div>
                    </div>
                 {/*}   <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Cibil Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_cibil_date}</p></div>
                    </div> */}
                 {/*}   <div class="row col-md-8 col d-print-inline-block">
                    <a href={imgpath+document_attachment}><button class="btn btn-dark" >View Document Attachment</button></a>
                  {/*}  <div class="row col-sm-6 col d-print-inline-block"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{nominee_contact}</p></div> 
                    </div> */}
                    </div>
                 
                   
                

                      
                      <hr/>


                      <h3 class="card-title">FIXED DEPOSIT (FD) AND WITHDRAWAL DETAILS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>FD Number:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{fd_number}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Code:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_code}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Scheme Name:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{scheme_name}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{deposit_date}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{deposit_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Applicable Interest Rate (yearly):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{applicable_yearly_interest_rate} %</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>WD Type:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{wd_type}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawal Type:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{withdrawal_type}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawal Status:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"><p>  <label className="badge badge-success" hidden={(withdrawal_status=="under review")||(withdrawal_status=="rejected")||(withdrawal_status=="completed")}>approved</label>
                    <label className="badge badge-warning" hidden={(withdrawal_status=="approved")||(withdrawal_status=="rejected"||(withdrawal_status=="completed"))}>under review</label>
                    <label className="badge badge-danger" hidden={(withdrawal_status=="under review")||(withdrawal_status=="approved")||(withdrawal_status=="completed")}>rejected</label>                       
                    <label className="badge badge-primary" hidden={(withdrawal_status=="under review")||(withdrawal_status=="approved"||(withdrawal_status=="rejected"))}>completed</label>                       
                    </p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration (in months):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_inmonths} months</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration (in days):</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays} days</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_date}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration Completed:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays_completed} days</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Duration Shortfall:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{duration_indays_shortfall} days</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Calculated for:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_fordays} days</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Interest Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{interest_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Maturity Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{maturity_amount}</p></div>
                    </div>
                    </div>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Processing Fee %:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{processing_fee_per}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Processing Fee Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{processing_fee_amount}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Withdrawal Amount:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>{total}</b></p></div>
                    </div>
                    </div>
                  {/*}  <div class="row d-print-flex" style={{textAlign:"left"}}>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Deposit Mode:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{mode}</p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Application Status:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>  <label className="badge badge-success" hidden={(application_status=="under review")||(application_status=="rejected")||(application_status=="completed")}>approved</label>
                    <label className="badge badge-warning" hidden={(application_status=="approved")||(application_status=="rejected"||(application_status=="completed"))}>under review</label>
                    <label className="badge badge-danger" hidden={(application_status=="under review")||(application_status=="approved")||(application_status=="completed")}>rejected</label>                       
                    <label className="badge badge-primary" hidden={(application_status=="under review")||(application_status=="approved"||(application_status=="rejected"))}>completed</label>                       
                    </p></div>
                    </div>
                    <div class="row col-md-4 col d-print-inline-block">
                    <div class="row col-sm-6 col d-print-inline-block"> <p><b>Approval Date:</b></p></div>
                    <div class="row col-sm-6 col d-print-inline-block"> <p>{approval_date}</p></div>
                    </div>
                    </div>*/}
                  

               
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                    <div class="row col-md-12 d-print-flex">
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Customer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Loan Officer Signature</b></p></div>
                    <div class="row col-sm-4 col d-print-inline-block text-center"> <p><b>Branch Seal & Signature</b></p></div>
                    </div>
                    </div>
                    
                    </div>
                    </div>

                    {/* Print Section End */}

                

                    <hr />
                    <h3 class="card-title">DOCUMENTS AND ATTACHMENTS</h3>
                    <div class="row d-print-flex" style={{textAlign:"left"}}>
                {/*}       <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Application Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{application_remarks}</p></div>
                    </div> */}
                  
                    <br/>
                    <a href={imgpath+document_attachment}><button class="btn btn-dark" >View Document Attachment</button></a>


                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                   
                {/*}    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+self_certification_image_link} style={{height:"90vh", width:"100%"}} /></div>
                    </row>*/}
                   
                    </div>
                  </div>
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_view_fd_withdrawal;