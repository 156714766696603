import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_fdrequests from './create_newaccounts';
import moment from 'moment/moment';


function Employee_fd_calculator(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");
 const custId=sessionStorage.getItem("CustomerId00");


 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

 
//Variables
const[data,setData] = useState([]);//SetData Variable

const[deposit_amount,setdeposit_amount]=useState();const updatedeposit_amount = (event) => { setdeposit_amount(event.target.value);}; const[deposit_amount_er,setdeposit_amount_er]=useState();
const[yearly_interest_rate,setyearly_interest_rate]=useState();const updateyearly_interest_rate = (event) => { setyearly_interest_rate(event.target.value);}; const[yearly_interest_rate_er,setyearly_interest_rate_er]=useState();
const[duration_indays,setduration_indays]=useState();const updateduration_indays = (event) => { setduration_indays(event.target.value);}; const[duration_indays_er,setduration_indays_er]=useState();
const[duration_inmonths,setduration_inmonths]=useState();const updateduration_inmonths = (event) => { setduration_inmonths(event.target.value);}; const[duration_inmonths_er,setduration_inmonths_er]=useState();
const[maturity_amount,setmaturity_amount]=useState();const updatematurity_amount = (event) => { setmaturity_amount(event.target.value);}; const[maturity_amount_er,setmaturity_amount_er]=useState();
const[monthly_interest_rate,setmonthly_interest_rate]=useState();const updatemonthly_interest_rate = (event) => { setmonthly_interest_rate(event.target.value);}; const[monthly_interest_rate_er,setmonthly_interest_rate_er]=useState();
const[daily_interest_rate,setdaily_interest_rate]=useState();const updatedaily_interest_rate = (event) => { setdaily_interest_rate(event.target.value);}; const[daily_interest_rate_er,setdaily_interest_rate_er]=useState();
const[interest_amount,setinterest_amount]=useState();const updateinterest_amount = (event) => { setinterest_amount(event.target.value);}; const[interest_amount_er,setinterest_amount_er]=useState();



 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();
 

/***************** FD CALCULATOR APPLICATION FUNCTION START  ******************/
function calculatefd(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){

    //Setting Error Variables
    setdeposit_amount_er(" "); 
    setyearly_interest_rate_er(" ");
    setduration_indays_er(" ");
    setduration_inmonths_er(" ");
    setmaturity_amount_er(" ");
    setmonthly_interest_rate_er(" ");
    setdaily_interest_rate_er(" ");
    seterror_msg(" ");
    
    
//Fetching Form Data
let noerror = 0;
const $deposit_amount = document.getElementById("deposit_amount").value; 
const $yearly_interest_rate = document.getElementById("yearly_interest_rate").value; 
const $duration_inmonths = document.getElementById("duration_inmonths").value; 

const $duration_in_days = $duration_inmonths*30;

const $monthly_interest_rate = (parseFloat($yearly_interest_rate/12)).toFixed(2);
const $daily_interest_rate = (parseFloat($monthly_interest_rate/30)).toFixed(2);

const $interest_amount = (parseFloat($deposit_amount * $daily_interest_rate)/100 * $duration_in_days).toFixed(2);
const $maturity_amount = (parseFloat($deposit_amount)+parseFloat($interest_amount)).toFixed(2);

setduration_indays($duration_in_days);
setinterest_amount($interest_amount);
setmonthly_interest_rate($monthly_interest_rate);
setdaily_interest_rate($daily_interest_rate);
setmaturity_amount($maturity_amount);


}
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** FD CALCULATOR FUNCTION END  ******************/


 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-fd-requests")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">FIXED DEPOSIT (FD) CALCULATOR</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Fixed Deposit (FD) Calculator</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deposit Amount<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="deposit_amount" value={deposit_amount} onChange={updatedeposit_amount} />
                              <code>{deposit_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Yearly Interest Rate<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="12.00" id="yearly_interest_rate" value={yearly_interest_rate} onChange={updateyearly_interest_rate} />
                              <code>{yearly_interest_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration in Month<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="duration_inmonths" value={duration_inmonths} onChange={updateduration_inmonths} />
                              <code>{duration_inmonths_er}</code>
                            </div>
                          </div>
                        
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} id="emp-submit-btn" onClick={calculatefd}>Calculate</button>

                        </div>
                        </div>
                        

                        <hr/>
                        <br/>
                        <br/>

                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration in Days</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="900" id="duration_indays" value={duration_indays} onChange={updateduration_indays} />
                              <code>{duration_indays_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000" id="interest_amount" value={interest_amount} onChange={updateinterest_amount} />
                              <code>{interest_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maturity Amount</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="15000" id="maturity_amount" style={{fontWeight:"bold"}} value={maturity_amount} onChange={updatematurity_amount} />
                              <code>{maturity_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Per Month</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1.00" id="monthly_interest_rate" value={monthly_interest_rate} onChange={updatemonthly_interest_rate} />
                              <code>{monthly_interest_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Interest Per Day</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="0.33" id="daily_interest_rate" value={daily_interest_rate} onChange={updatedaily_interest_rate} />
                              <code>{daily_interest_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                     
                     
                     
                     
                     </div>
                     

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                       
                       
    
                     
                   
                   {/*} </div> */}
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_fd_calculator;