import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';



function Employee_test_emp_adr(){

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");


  //SETTING DATA VARIABLES
  const[adr_number,setadr_number]=useState();const updateadr_number = (event) => { setadr_number(event.target.value);}; const[adr_number_er,setadr_number_er]=useState();
  const[confirm_adr_number,setconfirm_adr_number]=useState();const updateconfirm_adr_number = (event) => { setconfirm_adr_number(event.target.value);}; const[confirm_adr_number_er,setconfirm_adr_number_er]=useState();
  const[customer_id,setcustomer_id]=useState();const updatecustomer_id = (event) => { setcustomer_id(event.target.value);}; const[customer_id_er,setcustomer_id_er]=useState();
  const[confirm_customer_id,setconfirm_customer_id]=useState();const updateconfirm_customer_id = (event) => { setconfirm_customer_id(event.target.value);}; const[confirm_customer_id_er,setconfirm_customer_id_er]=useState();
  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();

 
/***************** CREATE TRANSACTION FUNCTION START  ******************/
function create_transaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setadr_number_er(" ");setconfirm_adr_number_er(" ");setcustomer_id_er(" ");setconfirm_customer_id_er(" ");seterror_msg(" ");
   
    
//Fetching Form Data
let noerror = 0;
const $adr_number = document.getElementById("adr_number").value; if($adr_number==""){ setadr_number_er("Adr Number is required !");noerror = noerror+1; }//date
const $confirm_adr_number = document.getElementById("confirm_adr_number").value; if($confirm_adr_number==""){ setconfirm_adr_number_er("Confirm Adr Number is required !");noerror = noerror+1; }//transaction
const $customer_id = document.getElementById("customer_id").value; if($customer_id==""){ setcustomer_id_er("Customer Id is required !");noerror = noerror+1; }//date
const $confirm_customer_id = document.getElementById("confirm_customer_id").value; if($confirm_customer_id==""){ setconfirm_adr_number_er("Confirm Adr Number is required !");noerror = noerror+1; }//transaction

const tokenA = "HS8SYSG9876";
//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("update-adr-number-by-employee", { 
  Log_id:logid,
  Token:tokenA,
  Adr_number:$adr_number,
  Confirm_adr_number:$confirm_adr_number,
  Customer_id:$customer_id,
  Confirm_customer_id:$confirm_customer_id,

 
  },{
    headers: { "Content-Type": "multipart/form-data" },
    }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setadr_number_er(response.data.validatorerror.Adr_number);
  setconfirm_adr_number_er(response.data.validatorerror.Confirm_adr_number);
  setcustomer_id_er(response.data.validatorerror.Customer_id);
  setconfirm_customer_id_er(response.data.validatorerror.Confirm_customer_id);
  seterror_msg("Cannot Update Adr Number !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

  setadr_number_er(response.data.adr_number_er);
  setconfirm_adr_number_er(response.data.confirm_adr_number_er);
  setcustomer_id_er(response.data.customer_id_er);
  setconfirm_customer_id_er(response.data.confirm_customer_id_er);
  seterror_msg("Cannot Update Adr Number !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Update Adr Number !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE TRANSACTION FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Adr Number Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="employee-dashboard-cash"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-customers")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">UPDATE ADR NUMBER</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Update Adr Number</h4>
                    <div class="form-sample">
                      <p class="card-description">update customer id adr from here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="r1234567" id="customer_id" value={customer_id} onChange={updatecustomer_id} />
                              <code>{customer_id_er}</code>
                            </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Customer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="r1234567" id="confirm_customer_id" value={confirm_customer_id} onChange={updateconfirm_customer_id} />
                              <code>{confirm_customer_id_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Adr Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="123456" id="adr_number" value={adr_number} onChange={updateadr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>enter last 6 digits of govt. ID</small>
                              <code>{adr_number_er}</code>
                            </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Adr Number</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="123456"  id="confirm_adr_number" value={confirm_adr_number} onChange={updateconfirm_adr_number} />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>enter last 6 digits of govt. ID</small>
                              <code>{confirm_adr_number_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>


                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={create_transaction}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_test_emp_adr;