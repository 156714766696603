import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import '../Resources/dashboard.css';

function Employeesidebar(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 
  //Variables
  const[data,setData] = useState([]);//SetData Variable
  //SETTING DATA VARIABLES
  const[permission_id,setpermission_id] = useState();//permssion_id
  const[customers_pm,setcustomers_pm]=useState(0);
  const[new_account_requests_pm,setnew_account_requests_pm]=useState(0);
  const[cash_pm,setcash_pm]=useState(0);
  const[bank_pm,setbank_pm]=useState(0);
  const[incomes_pm,setincomes_pm]=useState(0);
  const[expenses_pm,setexpenses_pm]=useState(0);
  const[loan_emis_pm,setloan_emis_pm]=useState(0);
  const[loan_requests_pm,setloan_requests_pm]=useState(0);
  const[loan_closure_requests_pm,setloan_closure_requests_pm]=useState(0);
  const[loan_schemes_pm,setloan_schemes_pm]=useState(0);
  const[transaction_history_pm,settransaction_history_pm]=useState(0);
  const[customer_history_pm,setcustomer_history_pm]=useState(0);
  const[loan_disbursals_pm,setloan_disbursals_pm]=useState(0);
  const[part_payment_requests_pm,setpart_payment_requests_pm]=useState(0);
  const[loan_groups_pm,setloan_groups_pm]=useState(0);
  const[fd_schemes_pm,setfd_schemes_pm]=useState(0);
  const[fd_requests_pm,setfd_requests_pm]=useState(0);
  const[fixed_deposits_pm,setfixed_deposits_pm]=useState(0);
  const[fd_withdrawals_pm,setfd_withdrawals_pm]=useState(0);
  const[rd_schemes_pm,setrd_schemes_pm]=useState(0);
  const[rd_requests_pm,setrd_requests_pm]=useState(0);
  const[recurring_deposits_pm,setrecurring_deposits_pm]=useState(0);
  const[rd_installments_pm,setrd_installments_pm]=useState(0);
  const[rd_withdrawals_pm,setrd_withdrawals_pm]=useState(0);
  const[update_adr_pm,setupdate_adr_pm]=useState(0);
  const[summary_pm,setsummary_pm]=useState(0);
  const[permission_status,setpermission_status]=useState(0);

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();


useEffect(()=>{
   
    getData();
    
    },[])


    

//******************* GET PERMISSIONS DATA FUNCTION START  ********************//
function getData(){


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)){
 
 axios.post("crm-get-employee-sidebar-permissions", {
   Log_id:logid,
   Token:token


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");



   //  window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){

   //Setting Data
  // setData(response.data.data.data);
  setpermission_id(response.data.permission_id);
  setcustomers_pm(response.data.customers_pm);
  setnew_account_requests_pm(response.data.new_account_requests_pm);
  setcash_pm(response.data.cash_pm);
  setbank_pm(response.data.bank_pm);
  setincomes_pm(response.data.incomes_pm);
  setexpenses_pm(response.data.expenses_pm);
  setloan_emis_pm(response.data.loan_emis_pm);
  setloan_requests_pm(response.data.loan_requests_pm);
  setloan_closure_requests_pm(response.data.loan_closure_requests_pm);
  setloan_schemes_pm(response.data.loan_schemes_pm);
  settransaction_history_pm(response.data.transaction_history_pm);
  setcustomer_history_pm(response.data.customer_history_pm);
  setloan_disbursals_pm(response.data.loan_disbursals_pm);
  setpart_payment_requests_pm(response.data.part_payment_requests_pm);
  setloan_groups_pm(response.data.loan_groups_pm);
  setfd_schemes_pm(response.data.fd_schemes_pm);
  setfd_requests_pm(response.data.fd_requests_pm);
  setfixed_deposits_pm(response.data.fixed_deposits_pm);
  setfd_withdrawals_pm(response.data.fd_withdrawals_pm);
  setrd_schemes_pm(response.data.rd_schemes_pm);
  setrd_requests_pm(response.data.rd_requests_pm);
  setrecurring_deposits_pm(response.data.recurring_deposits_pm);
  setrd_installments_pm(response.data.rd_installments_pm);
  setrd_withdrawals_pm(response.data.rd_withdrawals_pm);
  setupdate_adr_pm(response.data.update_adr_pm);
  setsummary_pm(response.data.summary_pm);
  setpermission_status(response.data.permission_status);

  }

  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Permissions !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/employee-login");
   
 
  }
 
  
 }
  
 //******************* GET PERMISSIONS DATA FUNCTION END  ********************//


 


return(
    <>
      {/*} partial:partials/_sidebar.html */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item">
            <a href="/employee-login" class="nav-link flex-column">
           <div  /*class="nav-profile-image" */ > 
                <img src="images/crmlogo.png" alt="profile" style={{height:"9rem", width:"9rem"}} />
                {/*}change to offline or busy as needed*/}
            </div>
            {/*}  <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">Sumit Enterprises</span>
                <span class="text-secondary icon-sm text-center">Id : 9887771</span>
              </div>*/}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-block" href="/employee-login">
            <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
              {/*}  <span class="font-weight-semibold mb-1 mt-2 text-center">Sumit Enterprises</span>
                <span class="text-secondary icon-sm text-center">Id : 9887771</span> */}
              </div>
            </a>
          </li>
          <hr/>
          <li class="pt-2 pb-1">
            <span class="nav-item-head">Dashboard</span>
          </li>
          <li class="nav-item" hidden={customers_pm==0}>
          <a class="nav-link" href="/employee-dashboard-customers">
              <i class="mdi mdi-account-multiple-outline menu-icon"></i>
              <span class="menu-title">Customers</span>
            </a>
          </li>
          <li class="nav-item"  hidden={new_account_requests_pm==0}>
            <a class="nav-link" href="/employee-dashboard-new-account-requests">
              <i class="mdi mdi-account-card-details menu-icon"></i>
              <span class="menu-title">New Account Requests</span>
            </a>
          </li>
          <li class="nav-item"  hidden={cash_pm==0}>
            <a class="nav-link" href="/employee-dashboard-cash">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Cash Management</span>
            </a>
          </li>
          <li class="nav-item"  hidden={bank_pm==0}>
            <a class="nav-link" href="/employee-dashboard-bank">
              <i class="mdi mdi-bank menu-icon"></i>
              <span class="menu-title">Bank Management</span>
            </a>
          </li>
          <li class="nav-item"  hidden={incomes_pm==0}>
            <a class="nav-link" href="/employee-dashboard-incomes">
              <i class="mdi mdi-drawing-box menu-icon"></i>
              <span class="menu-title">Income Management</span>
            </a>
          </li>
          <li class="nav-item"  hidden={expenses_pm==0}>
            <a class="nav-link" href="/employee-dashboard-expenses">
              <i class="mdi mdi-format-float-none menu-icon"></i>
              <span class="menu-title">Expenses Management</span>
            </a>
          </li>
       {/*}   <li class="nav-item">
            <a class="nav-link" href="/adminpanel-dashboard-employees">
              <i class="mdi mdi-account-circle menu-icon"></i>
              <span class="menu-title">Employees</span>
            </a>
          </li> */}
          <li class="nav-item"  hidden={loan_emis_pm==0}>
            <a class="nav-link" href="/employee-dashboard-loan-payments">
              <i class="mdi mdi-chart-areaspline menu-icon"></i>
              <span class="menu-title">Loan EMIs</span>
            </a>
          </li>
          <li class="nav-item"  hidden={loan_requests_pm==0}>
            <a class="nav-link" href="/employee-dashboard-loan-requests">
              <i class="mdi mdi-clipboard-check menu-icon"></i>
              <span class="menu-title">Loan Requests</span>
            </a>
          </li>
          <li class="nav-item" hidden={loan_disbursals_pm==0} >
            <a class="nav-link" href="/employee-dashboard-loan-disbursals">
              <i class="mdi mdi mdi-book-multiple-variant menu-icon"></i>
              <span class="menu-title">Loan Disbursals</span>
            </a>
          </li>
          <li class="nav-item" hidden={part_payment_requests_pm==0} >
            <a class="nav-link" href="/employee-dashboard-partpayment-requests">
              <i class="mdi mdi-box-shadow menu-icon"></i>
              <span class="menu-title">Part Payment Requests</span>
            </a>
          </li>
          <li class="nav-item"  hidden={loan_closure_requests_pm==0}>
            <a class="nav-link" href="/employee-dashboard-loan-closure-requests">
              <i class="mdi mdi-animation menu-icon"></i>
              <span class="menu-title">Loan Closure Requests</span>
            </a>
          </li>
          <li class="nav-item" hidden={loan_groups_pm==0} >
            <a class="nav-link" href="/employee-dashboard-loan-groups">
              <i class="mdi mdi-account-multiple menu-icon"></i>
              <span class="menu-title">Loan Groups</span>
            </a>
          </li>
          <li class="nav-item"  hidden={loan_schemes_pm==0}>
            <a class="nav-link" href="/employee-dashboard-loan-schemes">
              <i class="mdi mdi-assistant menu-icon"></i>
              <span class="menu-title">Loan Schemes</span>
            </a>
          </li>
          <hr/>
          <li class="nav-item"  hidden={transaction_history_pm==0}>
            <a class="nav-link" href="/employee-dashboard-transaction-history">
              <i class="mdi mdi-alarm-multiple menu-icon"></i>
              <span class="menu-title">Transaction History</span>
            </a>
          </li>
          <li class="nav-item"  hidden={customer_history_pm==0}>
            <a class="nav-link" href="/employee-dashboard-customer-updations">
              <i class="mdi mdi-animation menu-icon"></i>
              <span class="menu-title">Customer History</span>
            </a>
          </li>
          <hr/>
          <li class="nav-item"  hidden={fd_schemes_pm==0} >
            <a class="nav-link" href="/employee-dashboard-fd-schemes">
              <i class="mdi mdi-assistant menu-icon"></i>
              <span class="menu-title">FD Schemes</span>
            </a>
          </li>
          <li class="nav-item"  hidden={fd_requests_pm==0} >
            <a class="nav-link" href="/employee-dashboard-fd-requests">
              <i class="mdi mdi-clock-in menu-icon"></i>
              <span class="menu-title">FD Requests</span>
            </a>
          </li>
          <li class="nav-item"  hidden={fixed_deposits_pm==0} >
            <a class="nav-link" href="/employee-dashboard-fixed-deposits">
              <i class="mdi mdi-bookmark-check menu-icon"></i>
              <span class="menu-title">Fixed Deposits (FDs)</span>
            </a>
          </li>
          <li class="nav-item"  hidden={fd_withdrawals_pm==0} >
            <a class="nav-link" href="/employee-dashboard-fd-withdrawals">
              <i class="mdi mdi-clock-out menu-icon"></i>
              <span class="menu-title">FD Withdrawals</span>
            </a>
          </li>
          <hr/>
          <li class="nav-item"  hidden={rd_schemes_pm==0}  >
            <a class="nav-link" href="/employee-dashboard-rd-schemes">
              <i class="mdi mdi-assistant menu-icon"></i>
              <span class="menu-title">RD Schemes</span>
            </a>
          </li>
          <li class="nav-item"  hidden={rd_requests_pm==0} >
            <a class="nav-link" href="/employee-dashboard-rd-requests">
              <i class="mdi mdi-clock-fast menu-icon"></i>
              <span class="menu-title">RD Requests</span>
            </a>
          </li>
          <li class="nav-item"  hidden={recurring_deposits_pm==0} >
            <a class="nav-link" href="/employee-dashboard-recurring-deposits">
              <i class="mdi mdi-scale menu-icon"></i>
              <span class="menu-title">Recurring Deposits</span>
            </a>
          </li>
          <li class="nav-item" hidden={rd_installments_pm==0} >
            <a class="nav-link" href="/employee-dashboard-rd-installment-payments">
              <i class="mdi mdi-clock-start menu-icon"></i>
              <span class="menu-title">RD Installments</span>
            </a>
          </li>
          <li class="nav-item" hidden={rd_withdrawals_pm==0} >
            <a class="nav-link" href="/employee-dashboard-rd-withdrawals">
              <i class="mdi mdi-clock-out menu-icon"></i>
              <span class="menu-title">RD Withdrawals</span>
            </a>
          </li>

          <hr/>
          <li class="nav-item"  hidden={update_adr_pm==0} >
            <a class="nav-link" href="/employee-dashboard-update-adr-number">
              <i class="mdi mdi-account-search menu-icon"></i>
              <span class="menu-title">Update Customer Adr</span>
            </a>
          </li>
          <li class="nav-item" hidden={summary_pm==0} >
            <a class="nav-link" href="/employee-final-analysis">
              <i class="mdi mdi-calendar-multiple menu-icon"></i>
              <span class="menu-title">Summary</span>
            </a>
          </li>

         
       {/*}   <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#returns" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-truck-delivery menu-icon"></i>
              <span class="menu-title">Returns</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="returns">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-salesreturn">Inwards (Sales Return)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-purchasereturn">Outwards (Purchases Return)</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-incomes">
              <i class="mdi mdi-briefcase-upload menu-icon"></i>
              <span class="menu-title">Incomes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-expenses">
              <i class="mdi mdi-briefcase-download menu-icon"></i>
              <span class="menu-title">Expenses</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#outstanding" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              <span class="menu-title">Outstanding</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="outstanding">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-incomes">Outstanding Incomes</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-outstanding-expenses">Outstanding Expenses</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-creditor">
              <i class="mdi mdi-account-network menu-icon"></i>
              <span class="menu-title">Creditors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-user-debtors">
              <i class="mdi mdi-account-card-details menu-icon"></i>
              <span class="menu-title">Debtors</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="collapse" href="#taxes" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-chart-gantt menu-icon"></i>
              <span class="menu-title">GST and Taxes</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="collapse" id="taxes">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-sgst">SGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-cgst">CGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-igst">IGST</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/businesstools-user-tax">Taxes</a>
                </li>
              </ul>
            </div>
          </li> */}
        {/*}  <li class="nav-item">
            <a class="nav-link" href="pages/tables/basic-table.html">
              <i class="mdi mdi-tag-multiple menu-icon"></i>
              <span class="menu-title">Offers</span>
            </a>
          </li> */}
         
        </ul>
      </nav>
    </>

);

}

export default Employeesidebar;