import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_rdrequests from './create_newaccounts';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';



function Employee_view_installment(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });



 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

 
 //SETTING DATA VARIABLES
 const[customer_id,setcustomer_id]=useState();
 const[branch_id,setbranch_id]=useState();
 const[installment_no,setinstallment_no]=useState();
 const[rd_number,setrd_number]=useState();
 const[installment_date,setinstallment_date]=useState();
 const[payment_date,setpayment_date]=useState();
 const[installment,setinstallment]=useState();
 const[mode,setmode]=useState();
 const[bank_id,setbank_id]=useState();
 const[bank_name,setbank_name]=useState();
 const[installment_status,setinstallment_status]=useState();
 const[created_by,setcreated_by]=useState();
 const[created_by_user,setcreated_by_user]=useState();
 const[time_created,settime_created]=useState();
 const[date_created,setdate_created]=useState();
// const[transaction_id,settransaction_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[overdue_charges,setoverdue_charges]=useState();
 const[total,settotal]=useState();



  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW INSTALLMENT FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
 if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-employee-view-installment", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setcustomer_id(response.data.customer_id);
   setbranch_id(response.data.branch_id);
   setinstallment_no(response.data.installment_no);
   setrd_number(response.data.rd_number);
   setinstallment_date(response.data.installment_date);
   setpayment_date(response.data.payment_date);
   setinstallment(response.data.installment);
   setmode(response.data.mode);
   setbank_id(response.data.bank_id);
   setbank_name(response.data.bank_name);
   setinstallment_status(response.data.installment_status);
   setcreated_by(response.data.created_by);
   setcreated_by_user(response.data.created_by_user);
   settime_created(response.data.time_created);
   setdate_created(response.data.date_created);
   setbranch_code(response.data.branch_code);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setemail(response.data.email);
   setcontact(response.data.contact);
   setoverdue_charges(response.data.overdue_charges);
   settotal(response.data.total);
 //  setsalary_remarks(response.data.salary_remarks);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/"); 
 
  }
 
 }
  
 //******************* VIEW INSTALLMENT FUNCTION END  ********************//


 
 


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/employee-dashboard-rd-installment-payments")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">VIEW INSTALLMENT</p>
                  </a>
                </div>
      
              </div>
            </div>


                <div class="card"  id="invoice" ref={componentRef}>
              
                  <div>
                    <h2 style={{textAlign:"center", padding:"1rem"}}>INSTALLMENT PAYMENT SLIP</h2>
                  </div>
                  <hr/>
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">CUSTOMER DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customer Id</b></p></div>
                    <div class="row col-sm-6"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6"> <p>{contact}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6"> <p>{branch_code}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Status:</b></p></div>
                    <div class="row col-sm-6"> <p>
                    <label className="badge badge-success" hidden={installment_status=="due"}>{installment_status}</label>
                    <label className="badge badge-danger" hidden={installment_status=="paid"}>{installment_status}</label>
                    </p></div>
                    </div>
                

                      
                      <hr/>


                      <h3 class="card-title">INSTALLMENT DETAILS</h3>
              
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>rd No.:</b></p></div>
                    <div class="row col-sm-6"> <p>{rd_number}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>installment No.:</b></p></div>
                    <div class="row col-sm-6"> <p>{installment_no}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>installment Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{installment}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>installment Date:</b></p></div>
                    <div class="row col-sm-6"> <p>{installment_date}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Payment Date:</b></p></div>
                    <div class="row col-sm-6"> <p>{payment_date}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Mode:</b></p></div>
                    <div class="row col-sm-6"> <p>{mode}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>OD Charges:</b></p></div>
                    <div class="row col-sm-6"> <p>{overdue_charges}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Total:</b></p></div>
                    <div class="row col-sm-6"> <p>{total}</p></div>
                    </div>
              

                    <hr />
                    <div class="row col-md-6 ">
                    <div class="row col-sm-6"> <p><b>Created By:</b></p></div>
                    <div class="row col-sm-6"> <p>{created_by} ({created_by_user})</p></div>
                    </div>
                    <div class="row col-md-6 justify-content-right">
                    <div class="row col-sm-6"> <p><b>Created At:</b></p></div>
                    <div class="row col-sm-6"> <p>{date_created} ({time_created})</p></div>


                   
                    </div>
                      
                    </div>
                   
                    </div>
                
                    </div>
                    <hr/>
                    <p class="text-muted" style={{textAlign:"center"}}>This is a computer generated receipt and no seal or sign is required.</p>
                  </div>
                

                  <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
                  </button>
                  
                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_view_installment;