import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_customers from './create_customers';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function Admin_installmentpayments(){

  
  
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({

      content: () => componentRef.current,
    });



 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");


 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}





//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



 //Error Variable
 const[errorvar,seterrorvar] = useState();

//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);
const[filter_trno,setfilter_trno]=useState();const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_application_no,setfilter_application_no]=useState();const updatefilter_application_no = (event) => { setfilter_application_no(event.target.value);}; const[filter_application_no_er,setfilter_application_no_er]=useState();
const[filter_installment_date,setfilter_installment_date]=useState();const updatefilter_installment_date = (event) => { setfilter_installment_date(event.target.value);}; const[filter_installment_date_er,setfilter_installment_date_er]=useState();
const[filter_payment_date,setfilter_payment_date]=useState();const updatefilter_payment_date = (event) => { setfilter_payment_date(event.target.value);}; const[filter_payment_date_er,setfilter_payment_date_er]=useState();
const[filter_status,setfilter_status]=useState();const updatefilter_status = (event) => { setfilter_status(event.target.value);}; const[filter_status_er,setfilter_status_er]=useState();
const[filter_mode,setfilter_mode]=useState();const updatefilter_mode = (event) => { setfilter_mode(event.target.value);}; const[filter_mode_er,setfilter_mode_er]=useState();
const[filter_installment,setfilter_installment]=useState();const updatefilter_installment = (event) => { setfilter_installment(event.target.value);}; const[filter_installment_er,setfilter_installment_er]=useState();
const[filter_installment_start_date,setfilter_installment_start_date]=useState('1970-01-01');const updatefilter_installment_start_date = (event) => { setfilter_installment_start_date(event.target.value);}; const[filter_installment_start_date_er,setfilter_installment_start_date_er]=useState();
const[filter_installment_end_date,setfilter_installment_end_date]=useState('2100-12-12');const updatefilter_installment_end_date = (event) => { setfilter_installment_end_date(event.target.value);}; const[filter_installment_end_date_er,setfilter_installment_end_date_er]=useState();
const[filter_payment_start_date,setfilter_payment_start_date]=useState('1970-01-01');const updatefilter_payment_start_date = (event) => { setfilter_payment_start_date(event.target.value);}; const[filter_payment_start_date_er,setfilter_payment_start_date_er]=useState();
const[filter_payment_end_date,setfilter_payment_end_date]=useState('2100-12-12');const updatefilter_payment_end_date = (event) => { setfilter_payment_end_date(event.target.value);}; const[filter_payment_end_date_er,setfilter_payment_end_date_er]=useState();
const[filter_branch_code,setfilter_branch_code]=useState();const updatefilter_branch_code = (event) => { setfilter_branch_code(event.target.value);}; const[filter_branch_code_er,setfilter_branch_code_er]=useState();
const[filter_group_name,setfilter_group_name]=useState();const updatefilter_group_name = (event) => { setfilter_group_name(event.target.value);}; const[filter_group_name_er,setfilter_group_name_er]=useState();
const[filter_contact,setfilter_contact]=useState();const updatefilter_contact = (event) => { setfilter_contact(event.target.value);}; const[filter_contact_er,setfilter_contact_er]=useState();


 
//Variables
const[data,setData] = useState([]);//SetData Variable


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 





//******************* GET LOAN APPLICATIONS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("crm-admin-get-installments-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,
   //Filters
   'Filter_val' :filterval,
   'Filter_installment_date' : moment(filter_installment_date).format('YYYY-MM-DD'),
   'Filter_payment_date' :moment(filter_payment_date).format('YYYY-MM-DD'),
   'Filter_trno' :filter_trno,
   'Filter_application_no' :filter_application_no,
   'Filter_status' :filter_status,
   'Filter_mode' :filter_mode,
   'Filter_installment' :filter_installment,
   'Filter_installment_start_date' : moment(filter_installment_start_date).format('YYYY-MM-DD'),
   'Filter_payment_start_date' :moment(filter_payment_start_date).format('YYYY-MM-DD'),
   'Filter_installment_end_date' : moment(filter_installment_end_date).format('YYYY-MM-DD'),
   'Filter_payment_end_date' :moment(filter_payment_end_date).format('YYYY-MM-DD'),
   'Filter_branch_code' :filter_branch_code,
   'Filter_group_name' :filter_group_name,
   'Filter_contact' :filter_contact,


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    setfilter_installment_date_er(response.data.validatorerror.Filter_installment_date);
    setfilter_payment_date_er(response.data.validatorerror.Filter_payment_date);
    setfilter_trno_er(response.data.validatorerror.Filter_trno);
    setfilter_application_no_er(response.data.validatorerror.Filter_application_no);
    setfilter_status_er(response.data.validatorerror.Filter_status);
    setfilter_mode_er(response.data.validatorerror.Filter_mode);
    setfilter_installment_er(response.data.validatorerror.Filter_installment);
    setfilter_installment_start_date_er(response.data.validatorerror.Filter_installment_start_date);
    setfilter_payment_start_date_er(response.data.validatorerror.Filter_payment_start_date);
    setfilter_installment_end_date_er(response.data.validatorerror.Filter_installment_end_date);
    setfilter_payment_end_date_er(response.data.validatorerror.Filter_payment_end_date);
    setfilter_branch_code_er(response.data.validatorerror.Filter_branch_code);
    setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
    setfilter_contact_er(response.data.validatorerror.Filter_contact);
    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors  
    setfilter_installment_date_er(response.data.filter_installment_date_er);
    setfilter_payment_date_er(response.data.filter_payment_date_er);
    setfilter_trno_er(response.data.filter_trno_er);
    setfilter_application_no_er(response.data.filter_application_no_er);
    setfilter_status_er(response.data.filter_status_er);
    setfilter_mode_er(response.data.filter_mode_er);
    setfilter_installment_er(response.data.filter_installment_er);
    setfilter_installment_start_date_er(response.data.filter_installment_start_date_er);
    setfilter_payment_start_date_er(response.data.filter_payment_start_date_er);
    setfilter_installment_end_date_er(response.data.filter_installment_end_date_er);
    setfilter_payment_end_date_er(response.data.filter_payment_end_date_er);   
    setfilter_branch_code_er(response.data.filter_branch_code_er);
    setfilter_group_name_er(response.data.filter_group_name_er);
    setfilter_contact_er(response.data.filter_contact_er);
    seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET LOAN APPLICATIONS DATA FUNCTION END  ********************//



 
//******************* GET LEXCEL DATA FUNCTION START  ********************//
function getexcelData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){

    axios.post("crm-admin-get-installments-list-inexcel", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,
   //Filters
   'Filter_val' :filterval,
   'Filter_installment_date' : moment(filter_installment_date).format('YYYY-MM-DD'),
   'Filter_payment_date' :moment(filter_payment_date).format('YYYY-MM-DD'),
   'Filter_trno' :filter_trno,
   'Filter_application_no' :filter_application_no,
   'Filter_status' :filter_status,
   'Filter_mode' :filter_mode,
   'Filter_installment' :filter_installment,
   'Filter_installment_start_date' : moment(filter_installment_start_date).format('YYYY-MM-DD'),
   'Filter_payment_start_date' :moment(filter_payment_start_date).format('YYYY-MM-DD'),
   'Filter_installment_end_date' : moment(filter_installment_end_date).format('YYYY-MM-DD'),
   'Filter_payment_end_date' :moment(filter_payment_end_date).format('YYYY-MM-DD'),
   'Filter_branch_code' :filter_branch_code,
   'Filter_group_name' :filter_group_name,
   'Filter_contact' :filter_contact,


   })
 .then((response) => { 


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
  //Setting Data
  const exceldata =response.data.data;
   
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(exceldata);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "data.xlsx");
 }
 //Cannot Fetch Data
 else{
   seterrorvar("Cannot Download Excel File !");
 }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET EXCEL DATA FUNCTION END  ********************//


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

      <Sidebar />
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">RECURRING DEPOSIT (RD) INSTALLMENT PAYMENTS</p>
                  </a>
                </div>
             {/*}   <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i> </button> */}
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

          
            </div>

       

              {/* CASH TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Recurring Deposit (RD) Installment Payments</h4>
                    <p className="card-description">manage your recurring deposit installment payments here
                    </p>

                    <code>{error_msg}</code>

{/* FILTER SECTION START */}
<hr/>
<div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
<div class="d-flex justify-content-between">
<div>
<h4 style={{padding:'1rem'}}>Filters</h4>
</div>
<div>

<button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
<i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
</button>
</div>
</div>

<div class="row">
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Installment Start Date</label>
<div class="col-sm-8">
  <input type="date" class="form-control" placeholder="" id="filter_installment_start_date" value={filter_installment_start_date} onChange={updatefilter_installment_start_date}  />
  <code>{filter_installment_start_date_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Installment End Date</label>
<div class="col-sm-8">
  <input type="date" class="form-control" id="filter_installment_end_date" value={filter_installment_end_date} onChange={updatefilter_installment_end_date}  />
  <code>{filter_installment_end_date_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Tr No.</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="120"   id="filter_trno" value={filter_trno} onChange={updatefilter_trno}  />
  <code>{filter_trno_er}</code>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Status</label>
<div class="col-sm-8">
  <select class="form-control" style={{padding:"1rem"}}   id="filter_status" value={filter_status} onChange={updatefilter_status} >
  <option value="">All</option>
  <option value="paid">paid</option>
  <option value="due">due</option>
  </select>
  <code>{filter_status_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">RD Number</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="LN5555552400"   id="filter_application_no" value={filter_application_no} onChange={updatefilter_application_no}  />
  <code>{filter_application_no_er}</code>
</div>
</div>
</div>

<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Installment</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="2400"   id="filter_installment" value={filter_installment} onChange={updatefilter_installment}  />
  <code>{filter_installment_er}</code>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Payment Start Date</label>
<div class="col-sm-8">
  <input type="date" class="form-control" id="filter_payment_start_date" value={filter_payment_start_date} onChange={updatefilter_payment_start_date}  />
  <code>{filter_payment_start_date_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Payment End Date</label>
<div class="col-sm-8">
  <input type="date" class="form-control" id="filter_payment_end_date" value={filter_payment_end_date} onChange={updatefilter_payment_end_date}  />
  <code>{filter_payment_end_date_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Mode</label>
<div class="col-sm-8">
<select class="form-control" style={{padding:"1rem"}}   id="filter_mode" value={filter_mode} onChange={updatefilter_mode} >
  <option value="">All</option>
  <option value="cash">cash</option>
  <option value="bank">bank</option>
  </select>  <code>{filter_mode_er}</code>
</div>
</div>
</div>
{/*}    <div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Updated On</label>
<div class="col-sm-8">
  <input type="date" class="form-control"  id="filter_updated_on" value={filter_updated_on} onChange={updatefilter_updated_on}  />
  <code>{filter_updated_on_er}</code>
</div>
</div>
</div> */}
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Branch Code</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="BRANCH01"   id="filter_branch_code" value={filter_branch_code} onChange={updatefilter_branch_code}  />
  <code>{filter_branch_code_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Group Name</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="GROUP1"   id="filter_group_name" value={filter_group_name} onChange={updatefilter_group_name}  />
  <code>{filter_group_name_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Contact</label>
<div class="col-sm-8">
  <input type="text" class="form-control" placeholder="910000000000"   id="filter_contact" value={filter_contact} onChange={updatefilter_contact}  />
  <code>{filter_contact_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<button class="btn btn-primary" onClick={()=>{ setfilterval(1); getData(); }}>Apply Filters</button>
<br/>
<br/>
</div>
</div>





<hr/>                  
</div> 

{/*}   FILTER SECTION END */}


                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                        {/*}  <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button> */}
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div className="table-responsive"  id="invoice" ref={componentRef}>
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th><b>#</b></th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Tr No.</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Branch Code</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Customer Id</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Contact</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Group Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Nominee Name</th>
                       {/*}     <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Coapplicant Contact</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >RD Number</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Installment Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Payment Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Status</th>
                           <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Mode</th> 

                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Interest</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Discount</th> */}
                           {/*  <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Principle</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Interest</th> 
                           <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >GST</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Installment</th>
                         {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >OD Charges</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Total</th> */}
                           
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >View</th>
                           {/*} <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Edit</th> *}
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Total</th> */}
                        {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Edit</th> */}
                       {/*}     <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Updated At</th> */}

                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >View</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1} >Edit</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                         <tr key={ind}>
                          <td>{ind + 1}</td>
                          <td>{curElm.  Installment_sn}</td>
                          <td>{curElm.Tr_no}</td>
                          <td>{curElm.Branch_code}</td>
                          <td>{curElm.Customer_id}</td>
                          <td>{curElm.First_name} {curElm.Last_name}</td>
                          <td>{curElm.Contact}</td>
                          <td>{curElm.Group_name}</td>
                          <td>{curElm.Nominee_name}</td>
                         {/*} <td>{curElm.Coapplicant_contact}</td> */}
                          <td>{curElm.RD_number}</td>
                          <td>{curElm.Installment_date}</td>
                          <td>{curElm.Payment_date}</td>
                          <td><label className="badge badge-success" hidden={curElm.Installment_status=="due"}>paid</label><label className="badge badge-danger" hidden={curElm.Installment_status=="paid"}>due</label></td>
                          <td>{curElm.Mode}</td>
                         {/*} <td>loan Installment received in cash</td> */}
                         {/*} <td>{curElm.Emi_principle}</td>
                          <td>{curElm.Emi_interest}</td>
                          <td>{curElm.Emi_gst}</td> */}
                          <td>{curElm.Installment_amount}</td>
                         {/*} <td>{curElm.Overdue_charges}</td>
                          <td>{curElm.Total}</td> */}
                       
                           <td><button class="btn btn-dark" onClick={()=>{
                              sessionStorage.setItem("EditId00", curElm.Installment_sn);
                              window.location.replace("/admin-dashboard-view-rd-installment-receipt");
                            }}>View</button></td>
                         {/*}     <td><i className="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{
                            sessionStorage.setItem("EditId00", curElm.EMI_sn);
                            window.location.replace("/admin-dashboard-edit-rd-installment");
                          }}></i></td>
                         {/*} <td>000.00</td>
                          <td>3600.00</td>
                          <td>4000.00</td> */}
                      {/*}    <td>{curElm.Updated_by} <span hidden={curElm.C_account_type=="admin"}>(EMP ID: {curElm.Updated_by_user})</span><span hidden={curElm.C_account_type=="employee"}>(ADMIN ID: {curElm.Updated_by_user})</span></td>
                          <td>{curElm.Date_updated} ({curElm.Time_updated})</td> */}
                         {/*} <td><button class="btn btn-primary">View</button></td> */}
                         {/*} <td><i className="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}}></i></td> */}
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                    
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

              <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
              </button>
              <button class="btn btn-dark" style={{marginTop:"2rem",marginLeft:"2rem"}} onClick={getexcelData}><i class="mdi mdi-file-excel text-light"></i> Download</button>

              <br/><br/><br/>
            {/* CASH TRANSACTIONS TABLE SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Admin_installmentpayments;