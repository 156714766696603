import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import edit_fdrequests from './edit_newaccounts';



function Admin_edit_rdscheme(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");


 
 useEffect(()=>{
   
  getData();
  
  },[])
 

 //SETTING DATA VARIABLES
 
 //SETTING DATA VARIABLES
 //scheme_name
 const[scheme_name,setscheme_name]=useState();const updatescheme_name = (event) => { setscheme_name(event.target.value);}; const[scheme_name_er,setscheme_name_er]=useState();
 const[scheme_code,setscheme_code]=useState();const updatescheme_code = (event) => { setscheme_code(event.target.value);}; const[scheme_code_er,setscheme_code_er]=useState();
 const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();

 const[minimum_total_amount,setminimum_total_amount]=useState();const updateminimum_total_amount = (event) => { setminimum_total_amount(event.target.value);}; const[minimum_total_amount_er,setminimum_total_amount_er]=useState();
 const[maximum_total_amount,setmaximum_total_amount]=useState();const updatemaximum_total_amount = (event) => { setmaximum_total_amount(event.target.value);}; const[maximum_total_amount_er,setmaximum_total_amount_er]=useState();
 const[installments,setinstallments]=useState();const updateinstallments = (event) => { setinstallments(event.target.value);}; const[installments_er,setinstallments_er]=useState();
 const[installment_frequency_indays,setinstallment_frequency_indays]=useState();const updateinstallment_frequency_indays = (event) => { setinstallment_frequency_indays(event.target.value);}; const[installment_frequency_indays_er,setinstallment_frequency_indays_er]=useState();
  
 const[customers_limit,setcustomers_limit]=useState();const updatecustomers_limit = (event) => { setcustomers_limit(event.target.value);}; const[customers_limit_er,setcustomers_limit_er]=useState();
 const[duration_inmonths,setduration_inmonths]=useState();const updateduration_inmonths = (event) => { setduration_inmonths(event.target.value);}; const[duration_inmonths_er,setduration_inmonths_er]=useState();
 const[duration_indays,setduration_indays]=useState();const updateduration_indays = (event) => { setduration_indays(event.target.value);}; const[duration_indays_er,setduration_indays_er]=useState();
 const[maturity_yearly_interest_rate,setmaturity_yearly_interest_rate]=useState();const updatematurity_yearly_interest_rate = (event) => { setmaturity_yearly_interest_rate(event.target.value);}; const[maturity_yearly_interest_rate_er,setmaturity_yearly_interest_rate_er]=useState();
 const[prematurity_yearly_interest_rate,setprematurity_yearly_interest_rate]=useState();const updateprematurity_yearly_interest_rate = (event) => { setprematurity_yearly_interest_rate(event.target.value);}; const[prematurity_yearly_interest_rate_er,setprematurity_yearly_interest_rate_er]=useState();
 const[maximum_deposit,setmaximum_deposit]=useState();const updatemaximum_deposit = (event) => { setmaximum_deposit(event.target.value);}; const[maximum_deposit_er,setmaximum_deposit_er]=useState();
 const[minimum_deposit,setminimum_deposit]=useState();const updateminimum_deposit = (event) => { setminimum_deposit(event.target.value);}; const[minimum_deposit_er,setminimum_deposit_er]=useState();
 const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);}; const[details_er,setdetails_er]=useState();
 const[scheme_status,setscheme_status]=useState('inactive');const updatescheme_status = (event) => { setscheme_status(event.target.value);}; const[scheme_status_er,setscheme_status_er]=useState();
 
 
 //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();



  
  
//******************* GET SCHEME DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-get-rd-scheme-details-by-id", {
   Log_id:logid,
   Token:token,
   Edit_id:editId


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Scheme Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setscheme_name(response.data.scheme_name);
   setscheme_code(response.data.scheme_code);
   settype(response.data.type);
   setinstallments(response.data.installments);
   setinstallment_frequency_indays(response.data.installment_frequency_indays);
   setminimum_total_amount(response.data.minimum_total_amount);
   setmaximum_total_amount(response.data.maximum_total_amount);
   setduration_inmonths(response.data.duration_inmonths);
   setduration_indays(response.data.duration_indays);
   setmaturity_yearly_interest_rate(response.data.maturity_yearly_interest_rate);
   setprematurity_yearly_interest_rate(response.data.prematurity_yearly_interest_rate);
   setmaximum_deposit(response.data.maximum_deposit);
   setminimum_deposit(response.data.minimum_deposit);
   setcustomers_limit(response.data.customers_limit);
   setdetails(response.data.details);
   setscheme_status(response.data.scheme_status);


  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET SCHEME DATA FUNCTION END  ********************//

 



  
/***************** EDIT RD SCHEME FUNCTION START  ******************/
function edit_rdscheme(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)){


    //Setting Error Variables
   setscheme_name_er(" ");setscheme_code_er(" ");settype_er(" "); setminimum_total_amount_er(" "); setmaximum_total_amount_er(" "); setinstallments_er(" "); 
   setinstallment_frequency_indays_er(" ");setcustomers_limit_er(" "); setduration_indays_er(" "); setmaturity_yearly_interest_rate_er(" "); setscheme_status_er(" ");
   setprematurity_yearly_interest_rate_er(" "); setmaximum_deposit_er(" "); setminimum_deposit_er(" "); setdetails_er(" "); seterror_msg(" ");

    
//Fetching Form Data
let noerror = 0;
const $scheme_name = document.getElementById("scheme_name").value; if($scheme_name==""){ setscheme_name_er("Scheme Name is required !");noerror = noerror+1; }//scheme_name
const $scheme_code = document.getElementById("scheme_code").value; if($scheme_code==""){ setscheme_code_er("Scheme Code is required !");noerror = noerror+1; }//scheme_code
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//type
const $minimum_total_amount = document.getElementById("minimum_total_amount").value; if($minimum_total_amount==""){ setminimum_total_amount_er("Minimum Total Amount is required !");noerror = noerror+1; }//minimum_total_amount
const $maximum_total_amount = document.getElementById("maximum_total_amount").value; if($maximum_total_amount==""){ setmaximum_total_amount_er("Maximum Total Amount is required !");noerror = noerror+1; }//maximum_total_amount
const $installments = document.getElementById("installments").value; if($installments==""){ setinstallments_er("Installmentsis required !");noerror = noerror+1; }//installments
const $installment_frequency_indays = document.getElementById("installment_frequency_indays").value; if($installment_frequency_indays==""){ setinstallment_frequency_indays_er("Installment Frequency in Days is required !");noerror = noerror+1; }//installment_frequency_indays
const $customers_limit = document.getElementById("customers_limit").value; if($customers_limit==""){ setcustomers_limit_er("Customers Limit is required !");noerror = noerror+1; }//customers_limit
//const $duration_inmonths = document.getElementById("duration_inmonths").value; if($duration_inmonths==""){ setduration_inmonths_er("Duration in Months is required !");noerror = noerror+1; }//duration_inmonths
const $duration_indays = document.getElementById("duration_indays").value; if($duration_indays==""){ setduration_indays_er("Duration in Days is required !");noerror = noerror+1; }//duration_indays
const $maturity_yearly_interest_rate = document.getElementById("maturity_yearly_interest_rate").value; if($maturity_yearly_interest_rate==""){ setmaturity_yearly_interest_rate_er("Maturity Yearly Interest Rate is required !");noerror = noerror+1; }//maturity_yearly_interest_rate
const $prematurity_yearly_interest_rate = document.getElementById("prematurity_yearly_interest_rate").value; if($prematurity_yearly_interest_rate==""){ setprematurity_yearly_interest_rate_er("Prematurity Yearly Interest Rate is required !");noerror = noerror+1; }//prematurity_yearly_interest_rate
const $maximum_deposit = document.getElementById("maximum_deposit").value; if($maximum_deposit==""){ setmaximum_deposit_er("Maximum Deposit is required !");noerror = noerror+1; }//maximum_deposit
const $minimum_deposit = document.getElementById("minimum_deposit").value; if($minimum_deposit==""){ setminimum_deposit_er("Minimum Deposit is required !");noerror = noerror+1; }//minimum_deposit
const $details = document.getElementById("details").value; if($details==""){ setdetails_er("Details is required !");noerror = noerror+1; }//details



//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("crm-edit-rd-scheme-details", { 
  Log_id:logid,
  Token:token,
  Edit_id:editId,
  Scheme_name:$scheme_name,
  Scheme_code:$scheme_code,
  Type:$type,
  Minimum_total_amount:$minimum_total_amount,
  Maximum_total_amount:$maximum_total_amount,
  Installments:$installments,
  Installment_frequency_indays:$installment_frequency_indays,
  Customers_limit:$customers_limit,
  //Duration_inmonths:$duration_inmonths,
  Duration_indays:$duration_indays,
  Maturity_yearly_interest_rate:$maturity_yearly_interest_rate,
  Prematurity_yearly_interest_rate:$prematurity_yearly_interest_rate,
  Maximum_deposit:$maximum_deposit,
  Minimum_deposit:$minimum_deposit,
  Details:$details,
  Scheme_status:scheme_status,


  }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setscheme_name_er(response.data.validatorerror.Scheme_name);
  setscheme_code_er(response.data.validatorerror.Scheme_code);
  settype_er(response.data.validatorerror.Type);
  setminimum_total_amount_er(response.data.validatorerror.Minimum_total_amount);
  setmaximum_total_amount_er(response.data.validatorerror.Maximum_total_amount);
  setinstallments_er(response.data.validatorerror.Installments);
  setinstallment_frequency_indays_er(response.data.validatorerror.Installment_frequency_indays);
  setcustomers_limit_er(response.data.validatorerror.Customers_limit);
  //setduration_inmonths_er(response.data.validatorerror.Duration_inmonths);
  setduration_indays_er(response.data.validatorerror.Duration_indays);
  setmaturity_yearly_interest_rate_er(response.data.validatorerror.Maturity_yearly_interest_rate);
  setprematurity_yearly_interest_rate_er(response.data.validatorerror.Prematurity_yearly_interest_rate);
  setmaximum_deposit_er(response.data.validatorerror.Maximum_deposit);
  setminimum_deposit_er(response.data.validatorerror.Minimum_deposit);
  setdetails_er(response.data.validatorerror.Details);
  setscheme_status_er(response.data.validatorerror.Scheme_status);
  seterror_msg("Cannot Update RD Scheme !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
    setscheme_name_er(response.data.scheme_name_er);
    setscheme_code_er(response.data.scheme_code_er);
    settype_er(response.data.Type_er);
    setmaximum_total_amount_er(response.data.maximum_total_amount_er);
    setminimum_total_amount_er(response.data.minimum_total_amount_er);
    setinstallments_er(response.data.installments_er);
    setinstallment_frequency_indays_er(response.data.installment_frequency_indays_er);
    setcustomers_limit_er(response.data.customers_limit_er);
    //setduration_inmonths_er(response.data.duration_inmonths_er);
    setduration_indays_er(response.data.duration_indays_er);
    setmaturity_yearly_interest_rate_er(response.data.maturity_yearly_interest_rate_er);
    setprematurity_yearly_interest_rate_er(response.data.prematurity_yearly_interest_rate_er);
    setmaximum_deposit_er(response.data.maximum_deposit_er);
    setminimum_deposit_er(response.data.minimum_deposit_er);
    setdetails_er(response.data.details_er);
    setscheme_status_er(response.data.scheme_status);
    seterror_msg("Cannot Update RD Scheme !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("RD Scheme Not Updated !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT RD SCHEME FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Recurring Deposit (RD) Scheme Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-rd-schemes"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
          <div className="header-left">
           <button style={{marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-outline-info" onClick={()=>{ window.location.replace("/admin-dashboard-rd-schemes")}}>  Back </button>
            </div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">EDIT RECURRING DEPOSIT (RD) SCHEME</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Edit Recurring Deposit (RD) Scheme Details</h4>
                    <div class="form-sample">
                      <p class="card-description"></p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="LS1234567" id="scheme_code" value={scheme_code} /*onChange={updatescheme_code}*/ />
                              <small class="text-muted" style={{fontSize:"0.7rem"}}>this field cannot be changed</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Scheme Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular Rural Loan" id="scheme_name" value={scheme_name} onChange={updatescheme_name} />
                              <code>{scheme_name_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="regular yearly" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>     
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Customers Limit<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="500" id="customers_limit" value={customers_limit} onChange={updatecustomers_limit} />
                              <code>{customers_limit_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Minimum Total Investment<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="minimum_total_amount" value={minimum_total_amount} onChange={updateminimum_total_amount} />
                              <code>{minimum_total_amount_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maximum  Total Investment<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1095" id="maximum_total_amount" value={maximum_total_amount} onChange={updatemaximum_total_amount} />
                              <code>{maximum_total_amount_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">No. of Installments<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="36" id="installments" value={installments} onChange={updateinstallments} />
                              <code>{installments_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Installment Frequency (in days)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1095" id="installment_frequency_indays" value={installment_frequency_indays} onChange={updateinstallment_frequency_indays} />
                              <code>{installment_frequency_indays_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="scheme_status" value={scheme_status} onChange={updatescheme_status}>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{scheme_status_er}</code>
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Duration (in Days)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1095" id="duration_indays" value={duration_indays} onChange={updateduration_indays} />
                              <code>{duration_indays_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maturity Interest Rate (Yearly)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="12.00" id="maturity_yearly_interest_rate" value={maturity_yearly_interest_rate} onChange={updatematurity_yearly_interest_rate} />
                              <code>{maturity_yearly_interest_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label"> Pre-maturity Interest Rate (Yearly)<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10.00" id="prematurity_yearly_interest_rate" value={prematurity_yearly_interest_rate} onChange={updateprematurity_yearly_interest_rate} />
                              <code>{prematurity_yearly_interest_rate_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Minimum Deposit<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000.00" id="minimum_deposit" value={minimum_deposit} onChange={updateminimum_deposit} />
                              <code>{minimum_deposit_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Maximum Deposit<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="50000.00" id="maximum_deposit" value={maximum_deposit} onChange={updatemaximum_deposit} />
                              <code>{maximum_deposit_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div>                     
                        <div class="form-group">
                        <label for="exampleTextarea1">Details<code>*</code></label>
                        <textarea  class="form-control" rows="2" placeholder="here details" id="details" value={details} onChange={updatedetails} />
                        <code>{details_er}</code>
                        </div>

                    {/*}    <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} id="scheme_status" value={scheme_status} onChange={updatescheme_status}>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{scheme_status_er}</code>
                            </div>
                          </div>
                        </div> 
                        </div> */}
                      
                       
              {/*}          <div class="form-group">
                        <label for="exampleTextarea1">Details<code>*</code></label>
                        <textarea  class="form-control" rows="2" placeholder="here details" id="details" value={details} onChange={updatedetails} />
                        <code>{details_er}</code>
                        </div>

               {/*}       <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Attach Scheme Document</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" />
                              
                           
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png, .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div> */}
                      
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={edit_rdscheme}>Submit</button>
                      
                       
    
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_edit_rdscheme;